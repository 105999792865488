<template>
  <v-text-field
      :id="localId"
      :label="localLabel"
      :placeholder="'John Smith, J Smith, Smith, J S, USBC#'"
      :outlined="true"
      :name="localId"
      v-on:keyup="handleChange"
  >
  </v-text-field>
</template>

<script>
export default {
  name: "bowler_registration_search",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String
    },
  },
  data() {
    return {
      localId: this.id,
      localLabel: this.label
    }
  },
  methods: {
    handleChange: function(event) {
      let searchButton = document.getElementById('bowler_search_submit')
      if (event.target.value.length >= 3) {
        searchButton.disabled = false;
        searchButton.className = searchButton.className.replace("submit_inactive", "submit_active");
      } else {
        searchButton.disabled = true;
        searchButton.className = searchButton.className.replace("submit_active", "submit_inactive");
      }
    }
  },
}
</script>

<style scoped>

</style>