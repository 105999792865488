import { render, staticRenderFns } from "./oil_patterns_table.vue?vue&type=template&id=4358ba62&scoped=true&"
import script from "./oil_patterns_table.vue?vue&type=script&lang=js&"
export * from "./oil_patterns_table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4358ba62",
  null
  
)

export default component.exports