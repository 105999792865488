<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="oil_patterns"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                label="Search"
                class="filter-field"
                clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select :items="statusFilterList"
                      v-model="statusFilterValue"
                      label="Status"
                      clearable
                      class="filter-field"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="getStatusColor(item.status)"
                dark
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.url="{item}">
        <a :href="item.url" target="_blank">Graph</a>
      </template>
      <template v-slot:item.edit="props">
        <a :href="getEditLink(props.item)" class="bulk-action-link-button">Edit</a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "oil_patterns_table",
  props: {
    oil_patterns: {
      type: Array
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'oil_pattern_name'
        },

        {
          text: 'Status',
          value: 'status',
          align: 'center',
          filter: this.statusFilter
        },
        {
          text: 'Length',
          value: 'length',
          align: 'end'
        },
        {
          text: 'Volume',
          value: 'volume',
          align: 'end'
        },
        {
          text: 'Ratio',
          value: 'ratio',
          align: 'end'
        },
        {
          text: 'Graph',
          value: 'url',
          align: 'center'
        },
        {
          text: '',
          value: 'edit',
          align: 'end',
          sortable: false
        }
      ],
      search: '',
      statusFilterValue: '',
      statusFilterList: [
        {text: 'Approved', value: 'approved'},
        {text: 'Pending', value: 'pending'},
        {text: 'Rejected', value: 'rejected'}
      ]
    }
  },
  methods: {
    getStatusColor(status) {
      console.log(status)
      if (status === "approved") {
        return 'green'
      } else if (status === 'pending') {
        return 'orange'
      } else if (status === 'rejected') {
        return 'red'
      } else return 'none'
    },
    getCompletedColor(completed) {
      if (completed === "Complete") return 'green'
      else return 'none'
    },
    handleClick(value) {
      console.log("Clicked!: ", value.id)
    },
    getEditLink(value) {
      return '/oil_patterns/' + value.id + '/edit'
    },
    statusFilter(value) {
      if (!this.statusFilterValue) {
        return true
      } else {
        return value === this.statusFilterValue
      }
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>