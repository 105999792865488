<template>
  <div id="buy-button-target"
</template>

<script>
export default {
  name: "imported_by_button"
}
</script>

<style scoped>

</style>