const Tournament = {

    assignTournamentAdvancedOptionsListener() {
      const element = document.getElementById('toggle-advanced-options');
      if(element) {
          element.addEventListener('click', () => Tournament.tournamentAdvancedOptions())
      }
    },

    tournamentAdvancedOptions() {
        var button = document.getElementById('advance-options-container')
        if (button.style.display == 'none') {
            button.style.display = 'block'
        } else {
            button.style.display = 'none'
        }
    }
}

export default Tournament;