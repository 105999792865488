<template>
  <v-carousel v-model="model"
              cycle
              height="200px"
              interval="6000"
              show-arrows-on-hover>
    <v-carousel-item
        v-for="(color, i) in colors"
        :key="color.id"
    >
      <v-sheet
          :color="color.color"
          height="100%"
          tile
      >
        <v-row
            class="fill-height"
            align="center"
            justify="center"
        >
          <div class="text-h2" style="font-weight: bold; color: white;">
            {{ slogans[i] }}
          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "info-intro-carousel",
  data() {
    return {
      colors: [
        {id: 1, color: 'rgb(10,10,10)'},
        {id: 2, color: 'rgb(10,10,10)'},
        {id: 3, color: 'rgb(10,10,10)'},
      ],
      model: 0,
      slogans: [
          'Easier.',
          'Faster.',
          'More satisfying.'

      ]
    }
  }
}
</script>

<style scoped>

</style>