import EventListeners from "./event_listeners";




const BowlerTournamentRegistration = {

    assignBowlerNotFoundListener() {
        EventListeners.assignByClassName('bowler-not-found', 'click', () => BowlerTournamentRegistration.bowlerNotFound())
    },

    assignEnableSearchListener() {
        EventListeners.assignByClassName('bowler-search', 'keyup', () => BowlerTournamentRegistration.enableSearch())
    },


    bowlerNotFound() {
        var x = document.getElementById("bowler_reg_bowler_not_found");
        if (x.style.display == "block") {
            x.style.display = "none";
        } else {
            x.style.display = "block";
        }
    },
    enableSearch() {
        var x = document.getElementById("search_term").value;

        var button = document.getElementById("bowler_search_submit");
        if (x.length >= 3) {
            button.disabled = false;
            button.className = button.className.replace("submit_inactive", "submit_active");
        } else {
            button.disabled = true;
            button.className = button.className.replace("submit_active", "submit_inactive");
        }
    },
}

export default BowlerTournamentRegistration