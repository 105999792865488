<template>
  <div class="alphabet-container">
    <div v-for="index in 26"
         :key="index"
         class="square"
         v-bind:class="{'active-letter' : activeLetter(index)}"
         v-on:click="scrollToLetter(index)"
    >
      {{ String.fromCharCode(64 + index) }}
    </div>
  </div>
</template>

<script>
export default {
  name: "lane_assignment_alphabet",
  props: {
    letters: {
      type: Array
    },
    teams: {
      type: Boolean
    }
  },
  data() {
    return {
      localLetters: this.letters,
      localTeams: this.teams
    }
  },
  methods: {
    activeLetter: function (index) {
      return this.localLetters.filter(letter => letter === String.fromCharCode(64+index)).length > 0
    },
    scrollToLetter: function(index){
      const letter = String.fromCharCode(64+index)
      const anchor = this.teams ? `anchor-team-${letter}` : `anchor-${letter}`
      const element = document.getElementById(anchor)
      let top = (document.getElementById("body-container").offsetTop + 251)
      if(this.teams){
        top += (document.getElementById("teams-header").offsetTop - 100)
      }
      if(element){
        top += element.offsetTop
        window.scrollTo(0, top)
      }
    }
  },
  computed: {}
}
</script>

<style scoped>


</style>