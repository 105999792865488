<template>
  <v-chart class="chart" :option="option"/>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {LineChart} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart, {THEME_KEY} from "vue-echarts";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent
]);

export default {
  name: "CustomLineChart",
  components: {
    VChart
  },
  props: {
    chart_data: {
      type: String
    }
  },
  provide: {
    [THEME_KEY]: "light"
  },
  data() {
    return {
      option: {
        title: {
          text: JSON.parse(this.chart_data).title
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: JSON.parse(this.chart_data).legend
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: JSON.parse(this.chart_data).labels
        },
        yAxis: {
          type: 'value',
          min: this.yMin
        },
        series: JSON.parse(this.chart_data).data
      }
    };
  },
  methods: {
    yMin: function() {
      let tempMin = 300
      const data = JSON.parse(this.chart_data).data
      data.forEach(competitor => {
        competitor.data.forEach(x => {
          x < tempMin ? tempMin = x : false
        })
      })
      return (Math.round(tempMin / 10)*10) - 10
    }
  }
};
</script>

<style scoped>

</style>