const Tab = {

    assignTabListeners() {
        const x = document.getElementsByClassName('tab-button');

        if (x.length > 0) {
            for (let i = 0; i < x.length; i++) {
                const tabType = x[i].dataset.tabType;

                x[i].addEventListener('click', () => Tab.openPageTabs(tabType));
            }

            let active_tab = document.getElementById('tab_view')
            if (active_tab) Tab.openPageTabs(active_tab.value)
        }
    },



    openPageTabs(entityId) {

        var x = document.getElementsByClassName("tab-body-container");
        for (var i = 0; i < x.length; i++) {
            x[i].style.display = "none";
        }

        x = document.getElementsByClassName("tab");
        for (i = 0; i < x.length; i++) {
            x[i].className = x[i].className.replace(" tab-active", " tab-inactive")
        }

        document.getElementById(entityId + '-content').style.display = "block";

        var targetButton = document.getElementById(entityId + '-tab-button')
        targetButton.classList.remove('tab-inactive')
        targetButton.classList.add('tab-active')
    }
}

export default Tab
