<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="teams"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-row>
          <v-col>

            <v-text-field
                v-model="search"
                label="Search"
                class="filter-field"
                clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select :items="divisionFilterList"
                      v-model="divisionFilterValue"
                      label="Division"
                      clearable
                      class="filter-field"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.roster_count="{ item }">
        <template v-if="item.status === true">
          <v-chip
              :color="'orange'"
              dark
          >
            {{ item.roster_count }}
          </v-chip>
        </template>
        <template>
          {{ item.roster_count }}
        </template>
      </template>
      <template v-slot:item.edit="props">
        <a :href="getTeamLink(props.item)" class="bulk-action-link-button">Edit</a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "teams_table",
  props: {
    teams: {
      type: Array
    },
    divisions: {
      type: Array
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Team',
          align: 'start',
          value: 'team_name',
        },
        {
          text: 'Division',
          value: 'division_name',
          align: 'center',
          filter: this.divisionFilter
        },
        {
          text: 'Roster Count',
          value: 'roster_count',
          align: 'center',
        },
        {text: '', value: 'edit', sortable: false}
      ],
      search: '',
      divisionFilterValue: '',
      divisionFilterList: this.divisions,
      statusFilterValue: '',
      statusFilterList: [
        {text: 'Published', value: 'Published'},
        {text: 'Unpublished', value: 'Unpublished'}
      ]
    }
  },
  methods: {
    getTeamLink(value) {
      return '/teams/' + value.id
    },
    divisionFilter(value) {
      if (!this.divisionFilterValue) {
        return true
      } else {
        return value === this.divisionFilterValue
      }
    },
  },
  computed: {}
}
</script>

<style scoped>

</style>