import EventListeners from "./event_listeners";

const Menu = {

     addListeners() {
         EventListeners.assignByClassName('menu-pop-out', 'click', () => Menu.closeMainMenu());
         EventListeners.assignByClassName('header-login-container', 'click', () => Menu.displayMainMenu());
     },


    displayMainMenu() {
        var menuDiv = document.getElementsByClassName('menu-pop-out')[0]
        menuDiv.style.display = 'block'
    },

    closeMainMenu() {
        var menuDiv = document.getElementsByClassName('menu-pop-out')[0]
        menuDiv.style.display = 'none'
    }
}

export default Menu;