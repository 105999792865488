<template>
  <div>
    <div v-if="localStatus !== 'created'" class="processing-status-container">
      <span :key="localStatus">{{ getStatusText }}</span>
      <span v-if="!['uncreated', 'created'].includes(localStatus) && completionPercentage !== 100"
            :key="shiftSquadId">{{ completionPercentage }}%
      </span>
      <span v-if="localStatus === 'uncreated'">
        <a :href="/score_sheet_pairs/ + shiftSquadId">Generate now.</a>
      </span>
    </div>
    <div v-if="localStatus === 'created'" :key="attachmentPath" class="scoresheet-download-button">
      <a class="bulk-action-link-button score-sheet-pair-download-link" :href="attachmentPath">{{ getStatusText }}</a>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "shift_score_sheet_pair_status",
  props: {
    shift_squad: {
      type: Object
    }
  },
  data() {
    return {
      shiftSquadId: this.shift_squad.id,
      localStatus: this.shift_squad.score_sheet_pair_status,
      pairsComplete: this.shift_squad.pairs_complete,
      pairsTotal: this.shift_squad.pairs_total,
      refreshInterval: null,
      showRunningVar: false,
      info: null,
      attachmentPath: null,
      apiUrl: process.env.VUE_APP_API_HOST
    }
  },
  mounted() {
    this.getData()
    if (this.localStatus !== 'created') {
      this.reloadData()
    }
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  },
  methods: {
    reloadData() {
      this.refreshInterval = setInterval(() => {
        this.getData()
        if (this.localStatus === 'created') {
          this.showRunningVar = false
          clearInterval(this.refreshInterval)
        }
      }, 5000)
    },
    getData() {
      this.showRunningVar = true
      let completeURL = this.apiUrl + "/shift_squads/" + this.shiftSquadId
      axios
          .get(completeURL)
          .then(response => {
            this.localStatus = response.data.score_sheet_pair_status
            this.pairsComplete = response.data.pairs_complete
            this.pairsTotal = response.data.pairs_total
            this.attachmentPath = response.data.attachment_path
          })
    }
  },
  computed: {
    completionPercentage: function () {
      if (this.pairsTotal === 0) {
        return 0
      } else {
        return Math.round((this.pairsComplete / this.pairsTotal) * 100)
      }
    },
    capitalizeStatus: function () {
      if (this.localStatus) {
        return this.localStatus.charAt(0).toUpperCase() + this.localStatus.slice(1)
      } else {
        return ""
      }
    },
    getStatusText() {
      switch (this.localStatus) {
        case 'uncreated':
          return "Score Sheets by Pair aren't created yet."
        case 'processing':
          return 'Processing'
        case 'requested':
          return 'Creation has been requested and will begin soon.'
        case 'created':
          return 'Download Score Sheets by Pair'
      }
    }
  }
}
</script>

<style scoped>

</style>