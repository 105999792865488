import BowlerTournaments from './bowler_tournaments';


const CompetitorScoringRules = {


    assignOpenCompSquads() {
        const elements = document.getElementsByClassName('competition-select-checkbox');
        for (let x = 0; x < elements.length; x++) {
            const scoringRuleId = elements[x].dataset.scoringRuleId;
            elements[x].addEventListener('change', () => CompetitorScoringRules.openCompetitorSquads(scoringRuleId));
        }
    },

    openCompetitorSquads(squad_id) {
        BowlerTournaments.enableCompetitionSubmit();
        var x = document.getElementById('scoring_rule_cb_' + squad_id);
        var d = document.getElementById('scoring_rule_squads_' + squad_id);

        if (x.checked == true) {
            d.style.display = "block";
        } else {
            d.style.display = "none";
        }
    }

}

export default CompetitorScoringRules