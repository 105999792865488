<template>
  <div class="panel-container">
    <v-row>
      <div>
        <a class="bulk-action-link-button white-link add-record" :href="getNewLink()">Add new title</a>
      </div>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
            :headers="headers"
            :items="positions"
            :items-per-page="10"
            class="elevation-1"
        >
          <template v-slot:item.edit="props">
            <a :href="getEditLink(props.item)" class="bulk-action-link-button">Edit</a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "championship_scale_table",
  props: {
    positions: {
      type: Array
    },
    organizer_id: {
      type: Number
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Position',
          align: 'start',
          value: 'position',
        },
        {
          text: 'Points',
          value: 'points',
          align: 'end'
        },
        {
          text: '',
          value: 'edit',
          sortable: false,
          align: "end"
        }
      ],
    }
  },
  methods: {
    getEditLink(value) {
      return `/championship_scales/${value.id}/edit`
    },
    getNewLink() {
      return `/championship_scales/new?organizer_id=${this.organizer_id}`
    },

  }
}
</script>

<style scoped>

</style>