<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="qualifiers"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
                clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select :items="divisionNameFilterList"
                      v-model="divisionNameFilterValue"
                      label="Division"
                      clearable
                      class="filter-field"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.bowler_name="props">
        <a :href="getBowlerProfileLink(props.item)">{{props.item.bowler_name}}</a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "tournament_results_qualifiers_table",
  props: {
    qualifiers: {
      type: Array
    },
    division_name_filters: {
      type: Array
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Bowler',
          value: 'bowler_name',
          align: 'start'
        },
        {
          text: 'Division',
          value: 'division_name',
          align: 'center',
          filter: this.divisionNameFilter
        }
      ],
      search: '',
      divisionNameFilterValue: '',
      divisionNameFilterList: this.division_name_filters
    }
  },
  methods: {
    divisionNameFilter(value) {
      if (!this.divisionNameFilterValue) {
        return true
      } else {
        return value === this.divisionNameFilterValue
      }
    },
    getBowlerProfileLink(value) {
      return '/bowler_profiles/' + value.bowler_id
    }
  },
}
</script>

<style scoped>

</style>