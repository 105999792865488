<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="years"
        :items-per-page="10"
        class="elevation-1"
        mobile-breakpoint="300"
    >
      <template v-slot:item.year="props">
        <a :href="getResultsLink(props.item)">{{props.item.year}}</a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "results_years_table",
  props: {
    years: {
      type: Array
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Year',
          value: 'year',
          align: 'start',
        },
        {
          text: 'Events',
          value: 'tournament_count',
          align: 'end'
        },
      ],
      search: '',
    }
  },
  methods: {
    getResultsLink(value) {
      return '/year_results/' + value.year
    }
  }
}
</script>

<style scoped>

</style>