<template>
  <div>

    <v-card elevation="2">
      <v-card-title>
        Test
      </v-card-title>
    </v-card>
    <v-spacer></v-spacer>
  </div>
</template>

<script>
export default {
  name: "registration_import_result",
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      id: this.data.id,


    }

  }
}
</script>

<style scoped>

</style>