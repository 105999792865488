<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="tournaments"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-row>
          <v-col>

            <v-text-field
                v-model="search"
                label="Search"
                class="filter-field"
                clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete :items="organizerFilterList"
                      v-model="organizerFilterValue"
                      label="Organizer"
                      clearable
                      class="filter-field"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-select :items="publishedFilterList"
                      v-model="publishedFilterValue"
                      label="Published"
                      clearable
                      class="filter-field"
            ></v-select>
          </v-col>
          <v-col>
            <v-select :items="completedFilterList"
                      v-model="completedFilterValue"
                      label="Completed"
                      clearable
                      class="filter-field"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.published="{ item }">
        <v-chip v-if="item.published === 'Published'"
                :color="getPublishedColor(item.published)"
                dark
        >
          {{ item.published }}
        </v-chip>
        <template v-if="item.published !== 'Published'">
          {{ item.published }}
        </template>
      </template>
      <template v-slot:item.completed="{ item }">
        <v-chip v-if="item.completed === 'Complete'"
                :color="getCompletedColor(item.completed)"
                dark
        >
          {{ item.completed }}
        </v-chip>
        <template v-if="item.completed !== 'Complete'">
          {{ item.completed }}
        </template>
      </template>
      <template v-slot:item.edit="props">
        <a :href="getDashboardLink(props.item)" class="bulk-action-link-button">Edit</a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "tournaments_table",
  props: {
    tournaments: {
      type: Array
    },
    organizers: {
      type: Array
    }
  },
  data() {
    return {
      headers: [
        {text: 'Date', value: 'earliest_date'},
        {
          text: 'Title',
          align: 'start',
          value: 'title',
        },
        {
          text: 'Organizer',
          value: 'organizer_name',
          filter: this.organizerFilter
        },
        {
          text: 'Published',
          value: 'published',
          align: 'center',
          filter: this.publishedFilter
        },
        {
          text: 'Complete',
          value: 'completed',
          align: 'center',
          filter: this.completedFilter
        },
        {text: '', value: 'edit', sortable: false}
      ],
      search: '',
      completedFilterValue: '',
      completedFilterList: [
        {text: 'Completed', value: 'Complete'},
        {text: 'Incomplete', value: 'Incomplete'}
      ],
      publishedFilterValue: '',
      publishedFilterList: [
        {text: 'Published', value: 'Published'},
        {text: 'Unpublished', value: 'Unpublished'}
      ],
      organizerFilterValue: '',
      organizerFilterList: this.organizers
    }
  },
  methods: {
    getPublishedColor(published) {
      if (published === "Published") return 'green'
      else return 'none'
    },
    getCompletedColor(completed) {
      if (completed === "Complete") return 'green'
      else return 'none'
    },
    handleClick(value) {
      console.log("Clicked!: ", value.id)
    },
    getDashboardLink(value) {
      return '/tournament_dashboards/' + value.id
    },
    completedFilter(value) {
      if (!this.completedFilterValue) {
        return true
      } else {
        return value === this.completedFilterValue
      }
    },
    publishedFilter(value) {
      if (!this.publishedFilterValue) {
        return true
      } else {
        return value === this.publishedFilterValue
      }
    },
    organizerFilter(value) {
      if (!this.organizerFilterValue) {
        return true
      } else {
        return value === this.organizerFilterValue
      }
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>