import ShopifyBuy from '@shopify/buy-button-js'

const Buy_button = {

    addBuyButton() {
        var client = ShopifyBuy.buildClient({
            domain: 'georgia-youth-bowlers-tour.myshopify.com',
            storefrontAccessToken: '95b48f68866857c419c251462a702919',
        });

        var shopifyDiv = document.getElementById('buy-button-target')

        if (shopifyDiv) {

            var shopifyProductId = shopifyDiv.dataset.shopify

            var ui = ShopifyBuy.UI.init(client);

            ui.createComponent('product', {
                id: shopifyProductId,
                node: document.getElementById('buy-button-target'),
                moneyFormat: '%24%7B%7Bamount%7D%7D',
                options: {
                    "product": {
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "calc(25% - 20px)",
                                    "margin-left": "20px",
                                    "margin-bottom": "50px"
                                }
                            },
                            "button": {
                                ":hover": {
                                    "background-color": "#e63e00"
                                },
                                "background-color": "#ff4500",
                                ":focus": {
                                    "background-color": "#e63e00"
                                }
                            }
                        },
                        "text": {
                            "button": "Add to cart"
                        }
                    },
                    "productSet": {
                        "styles": {
                            "products": {
                                "@media (min-width: 601px)": {
                                    "margin-left": "-20px"
                                }
                            }
                        }
                    },
                    "modalProduct": {
                        "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true
                        },
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "100%",
                                    "margin-left": "0px",
                                    "margin-bottom": "0px"
                                }
                            },
                            "button": {
                                ":hover": {
                                    "background-color": "#e63e00"
                                },
                                "background-color": "#ff4500",
                                ":focus": {
                                    "background-color": "#e63e00"
                                }
                            }
                        },
                        "text": {
                            "button": "Add to cart"
                        }
                    },
                    "option": {},
                    "cart": {
                        "styles": {
                            "button": {
                                ":hover": {
                                    "background-color": "#e63e00"
                                },
                                "background-color": "#ff4500",
                                ":focus": {
                                    "background-color": "#e63e00"
                                }
                            }
                        },
                        "text": {
                            "total": "Subtotal",
                            "button": "Checkout"
                        }
                    },
                    "toggle": {
                        "styles": {
                            "toggle": {
                                "background-color": "#ff4500",
                                ":hover": {
                                    "background-color": "#e63e00"
                                },
                                ":focus": {
                                    "background-color": "#e63e00"
                                }
                            }
                        }
                    }
                },
            });
        }
    }
}

export default Buy_button