import EventListeners from "./event_listeners";

const CompetitorScores = {

    assigEnableCompetitorScoreSaveListener() {
        EventListeners.assignByClassName('comp-score-value-entry', 'keyup', () => CompetitorScores.enableCompetitorScoreSave())
    },


    enableCompetitorScoreSave() {
        var x = document.getElementById("competitor_score_save")
        x.disabled = false;
        x.className = x.className.replace(" submit_inactive", " submit_active");
    }
}

export default CompetitorScores