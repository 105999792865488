<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Advanced Options
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <checkbox_hidden_field
              :label="'Ties consume lower positions'"
              :value="tiesConsumePosition"
              :name="'tournament[ties_consume_position]'"
              :id="'tournament_ties_consume_position'"
          >
          </checkbox_hidden_field>
          <checkbox_hidden_field
              :label="'Allow re-entries'"
              :value="allowReentry"
              :name="'tournament[allow_reentry]'"
              :id="'tournament_allow_reentry'"
          >
          </checkbox_hidden_field>
          <checkbox_hidden_field
              :label="'College event'"
              :value="collegeFlag"
              :name="'tournament[college]'"
              :id="'tournament_college'"
          >
          </checkbox_hidden_field>
          <v-text-field v-if="validOrganizer"
                        label="Shopify product ID"
                        :value="shopifyId"
                        :hint="'Found in the Buy Button Code'"
                        :outlined="true"
                        :id="'tournament_shopify_id'"
                        :name="'tournament[shopify_id]'"
                        :error-messages="shopifyIdErrorMessage"
          >
          </v-text-field>
          <checkbox_hidden_field v-if="validOrganizer"
              :label="'Shopify sales active'"
              :value="shopifyActive"
              :name="'tournament[shopify_active]'"
              :id="'tournament_shopify_active'"
          >
          </checkbox_hidden_field>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "tournament_advanced_options",
  props: {
    organizer_id: {
      type: Number
    },
    tournament: {
      type: Object
    },
    shopify_id: {
      type: Number
    },
    shopify_enabled: {
      type: Boolean
    },
    shopify_active: {
      type: Boolean
    },
    shopify_id_error_message: {
      type: Array
    }
  },
  data() {
    return {
      shopifyEnabled: this.shopify_enabled,
      tiesConsumePosition: this.tournament.ties_consume_position === null ? false : this.tournament.ties_consume_position,
      allowReentry: this.tournament.allow_reentry === null ? false : this.tournament.allow_reentry,
      shopifyId: this.shopify_id,
      shopifyActive: this.shopify_active,
      shopifyIdErrorMessage: this.shopify_id_error_message,
      collegeFlag: this.tournament.college === null ? false : this.tournament.college
    }
  },
  computed: {
    validOrganizer() {
      return this.shopifyEnabled
    }
  }
}
</script>

<style scoped>

</style>