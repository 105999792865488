<template>
  <div class="field">
    <input
        type="datetime-local"
        name="shift_tournament[shifttime]"
        class="input data-time-input"
        id="shift_tournament_shifttime"
        v-model="shift.time"
    />
  </div>
</template>

<script>

export default {
  props: {
    shift: {
      type: Date
    }},
  name: "shift_tournament_datetime",
  components: {
  },
}
</script>

<style scoped>
  .field {
    border: 1px solid black;
    width: fit-content;
  }
</style>