<template>
  <div>
    <v-checkbox
        v-model="localValue"
        :label="localLabel"
        v-on:change="toggleAdvancedOption"
        :error-messages="errorMessages"
    >
    </v-checkbox>
    <input
        :id="localId"
        type="hidden"
        :name="localName"
        :value="localValue"
        :key="localValue"
    >
  </div>
</template>

<script>
export default {
  name: "checkbox_hidden_field",
  props: {
    id: {
      type: String
    },
    name: {
      type: String
    },
    label: {
      type: String
    },
    value: {
      type: Boolean
    },
    advanced_option: {
      type: String
    },
    error_messages: {
      type: Array
    }
  },
  data() {
    return {
      localId: this.id,
      localName: this.name,
      localLabel: this.label,
      localValue: this.value,
      localAdvancedOption: this.advanced_option,
      errorMessages: this.error_messages || null
    }
  },
  methods: {
    handleCheckboxChange() {
      this.toggleAdvancedOption()
    },
    toggleAdvancedOption: function () {
      if (this.localAdvancedOption) {
        let advancedOption = document.getElementById(this.localAdvancedOption)
        if (this.localValue) {
          advancedOption.style.display = 'block'
        } else {
          advancedOption.style.display = 'none'
        }
      }
    }
  }
}
</script>

<style scoped>

</style>