<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="awards"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
                clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select :items="awardTitleFilterList"
                      v-model="awardTitleFilterValue"
                      label="Award Title"
                      clearable
                      class="filter-field"
            ></v-select>
          </v-col>
          <v-col>
            <v-select :items="divisionNameFilterList"
                      v-model="divisionNameFilterValue"
                      label="Division"
                      clearable
                      class="filter-field"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.competitor_name="{item}">
        <div v-if="item.competitor_type === 'Bowler'">
          <a :href="getBowlerProfileLink(item)">{{ item.competitor_name }}</a>
        </div>
        <div v-if="item.competitor_type !== 'Bowler'">
          {{ item.competitor_name }}
        </div>
      </template>
      <template v-slot:item.amount="props">
        {{ getCurrentAmount(props.item.amount) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "tournament_results_awards_table",
  props: {
    awards: {
      type: Array
    },
    award_title_filters: {
      type: Array
    },
    division_name_filters: {
      type: Array
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Title',
          value: 'award_title',
          filter: this.awardTitleFilter
        },
        {
          text: 'Division',
          value: 'division_name',
          align: 'center',
          filter: this.divisionNameFilter
        },
        {
          text: 'Competitor',
          value: 'competitor_name',
          align: 'start'
        },
        {
          text: 'Note',
          value: 'note',
          align: 'start',
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'end'
        }
      ],
      search: '',
      awardTitleFilterValue: '',
      awardTitleFilterList: this.award_title_filters,
      divisionNameFilterValue: '',
      divisionNameFilterList: this.division_name_filters
    }
  },
  methods: {
    getCurrentAmount(value) {
      return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(value)
    },
    getBowlerProfileLink(value) {
      return '/bowler_profiles/' + value.competitor_id
    },
    awardTitleFilter(value) {
      if (!this.awardTitleFilterValue) {
        return true
      } else {
        return value === this.awardTitleFilterValue
      }
    },
    divisionNameFilter(value) {
      if (!this.divisionNameFilterValue) {
        return true
      } else {
        return value === this.divisionNameFilterValue
      }
    }
  },
}
</script>

<style scoped>

</style>