<template>
  <v-card width="275px"
          height="400px"
          style="margin: 0 20px 40px 0;"
  >
    <v-container v-if="tournament.in_progress" class="status-container">
      Live
    </v-container>
    <v-container v-if="!tournament.in_progress && tournament.upcoming" class="status-container status-upcoming">
      Upcoming
    </v-container>
    <v-container v-if="!tournament.in_progress && tournament.recently_completed" class="status-container status-completed">
      Completed
    </v-container>
    <v-card-title>
      {{ tournament.earliest_date }}
    </v-card-title>
    <v-card-subtitle>
      {{ tournament.organizer_name }}
    </v-card-subtitle>
    <v-card-text class="tournament-title-container">
      <p>{{ tournament.title }}</p>
      <v-container>
        <div v-if="tournament.bowlers > 0">
          <v-icon>mdi-account</v-icon>
          <span class="para-1">{{ tournament.bowlers }}</span>
        </div>
        <div v-if="tournament.teams > 0">
          <v-icon>mdi-account-group</v-icon>
          <span class="para-1">{{ tournament.teams }}</span>
        </div>
        <div v-if="tournament.junior_gold">
          <v-icon>mdi-shield-half-full</v-icon>
          <span class="para-1">Junior Gold Qualifier</span>
        </div>
        <div v-if="tournament.bowling_center">
          <v-icon>mdi-home</v-icon>
          <span class="para-1">{{ tournament.bowling_center.bowlingcentername }}</span>
        </div>
        <div v-if="tournament.bowling_center">
          <v-icon>mdi-google-maps</v-icon>
          <span class="para-1">{{ `${tournament.bowling_center.city}, ${tournament.bowling_center.state}` }} </span>
        </div>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn :href="getTournamentResultsLink(tournament)">View</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "tournament_card",
  props: {
    tournament: {
      type: Object
    }
  },
  data() {
    return {}
  },
  methods: {
    getTournamentResultsLink(value) {
      return '/tournament_results/' + value.id
    }
  },
}
</script>

<style scoped>


</style>