<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="transactions"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
            clearable
        ></v-text-field>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "organizer_transactions_table",
  props: {
    transactions: {
      type: Array
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Date',
          align: 'start',
          value: 'date',
        },
        {
          text: 'Type',
          value: 'type',
          align: 'start'
        },
        {
          text: 'Title',
          value: 'title',
          align: 'start'
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'end'
        },
        {
          text: 'Note',
          value: 'note',
          align: 'start'
        },
      ],
      search: '',
    }
  },
}
</script>

<style scoped>

</style>