<template>
  <v-app-bar
      color="#405d8c"
      dark
      max-height="65px"
  >
    <v-app-bar-nav-icon @click="openSideNav"></v-app-bar-nav-icon>
    <v-toolbar-title>
      <a href="https://www.bowlmetrix.com">
        <v-img
            src="/BowlMetriXWhiteText.png"
            max-height="50px"
            contain
        ></v-img>
      </a>
    </v-toolbar-title>

  </v-app-bar>

</template>

<script>
export default {
  name: "top_nav_main",
  data() {
    return {
      drawer: this.drawer_is_open
    }
  },
  methods: {
    openSideNav: function () {
      this.$root.$data.store.openSideNav()
    }
  }
}
</script>

<style scoped>

</style>