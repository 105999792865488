<template>
  <div>

    <v-autocomplete
        v-on:change="filterScoringRules"
        v-model="selectedTournament"
        :label="'Tournament'"
        :placeholder="'Tournament'"
        :items="tournaments"
        :clearable="true"
        :outlined="true"
        :name="'id'"
    >
    </v-autocomplete>
    <v-autocomplete
        :disabled="!selectedTournament"
        :label="'Competition'"
        :placeholder="'Competition'"
        :items="scoringRules"
        :clearable="true"
        :outlined="true"
        :name="'scoring_rule_id'"
    >
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "score_export_selection",
  props: {
    tournaments: {
      type: Array
    },
    scoring_rules: {
      type: Array
    },
  },
  data() {
    return {
      localTournaments: this.tournaments,
      scoringRules: this.scoring_rules,
      selectedTournament: null
    }
  },
  methods: {
    filterScoringRules(value) {
      const filteredScoringRules = this.scoring_rules.filter(scoringRule => scoringRule.tournament_id === value)
      this.scoringRules = filteredScoringRules
    }
  }
}
</script>

<style scoped>

</style>