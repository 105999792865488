const RegistrationImport = {

    enableUnregisterAllButton (){
        document.getElementById('bulk-delete-button').disabled = false
    },

    disableUnregisterAllButton (){
        document.getElementById('bulk-delete-button').disabled = false
    }
}

export default RegistrationImport