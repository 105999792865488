<template>
  <div>
    <GChart
        type="AreaChart"
        :data="chartData"
        :options="chartOptions"
    />
  </div>
</template>

<script>

export default {
  name: "scoring_rule_result_block_averages",
  props: {
    averages: {
      type: Array
    }
  },
  data() {
    return {
      chartData: this.averages
    }
  },
  methods: {
    findLowest(data) {
      let foundMin = 300
      let tempData = data.slice(1)
      tempData.forEach(row => row.slice(1).forEach(value => {
        if(value < foundMin){
          foundMin = value
        }
      }))
      return ((Math.round(foundMin/10) * 10) - 10)
    },
    findHighest(data){
      let foundMax = 0
      let tempData = data.slice(1)
      tempData.forEach(row => row.slice(1).forEach(value => {
        if(value > foundMax){
          foundMax = value
        }
      }))
      return ((Math.round(foundMax/10) * 10) + 10)
    }
  },
  computed: {
    chartOptions: function () {
      let minValue = this.findLowest(this.chartData)
      let maxValue = this.findHighest(this.chartData)
      return {
        chart: {
          title: "Block Averages",
        },
        colors: ['#379683', '#557A95'],
        vAxis: {
          minValue: minValue,
          maxValue: 300,
          viewWindow: {
            min: minValue,
            max: maxValue
          }
        },
        curveType: 'function',
      }
    }
  }
}
</script>

<style scoped>

</style>