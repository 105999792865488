<template>
  <div>
    <v-divider></v-divider>
    <div class="date-range-select-container">
      <v-select
          id="timeSpan"
          :items="timeSpanOptions"
          :value="timeSpan"
          :v-model="timeSpanSelected"
          @input="getStatisticsData"
      ></v-select>
    </div>
    <div v-if="mixedChartDataSet && mixedChartDataSet[0].data.length > 0 && gaugeAverage !== 0"
         class="flex flex-wrap charts-container">
      <div class="chart-container">
        <custom_gauge_chart
            :key="gaugeKey"
            :average="gaugeAverage"
            :chart_title="gaugeTitle"
        ></custom_gauge_chart>
      </div>
      <div class="chart-container">
        <bowler_profile_absolute_deviation
            :option="absoluteDeviationChartOptions"
        ></bowler_profile_absolute_deviation>
      </div>
      <div class="chart-container">
        <custom_mixed_chart
            :option="mixedChartOptions"></custom_mixed_chart>
      </div>
    </div>
    <div v-if="!mixedChartDataSet || mixedChartDataSet[0].data.length === 0 || gaugeAverage === 0">
      No data found
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BowlerProfileStats",
  props: {
    id: {
      type: Number
    },
  },
  data() {
    return {
      passedChartData: null,
      mixedChartDataSet: null,
      gaugeTitle: "Average",
      mixedLegend: "",
      averageLabels: null,
      gaugeAverage: this.average,
      gaugeAbsoluteDeviation: null,
      gaugeKey: 1,
      gameAveragesKey: 2,
      absoluteDeviationKey: 3,
      apiUrl: process.env.VUE_APP_API_HOST,
      timeSpan: 90,
      timeSpanSelected: -1,
      currentTimeSpan: 90,
      timeSpanOptions: [
        {
          text: '3 Months',
          value: 90
        },
        {
          text: '6 Months',
          value: 180
        },
        {
          text: '12 Months',
          value: 365
        },
      ],
    }
  },
  mounted() {
    this.getStatisticsData(90)
  },
  methods: {
    getStatisticsData(value) {
      if (this.timeSpanSelected !== this.currentTimeSpan) {
        let completeURL = this.apiUrl + "/bowler_profile_statistics/" + this.id + "?time_span=" + value
        axios
            .get(completeURL)
            .then(response => {
              this.gaugeAverage = response.data.average
              this.mixedChartDataSet = response.data.game_averages
              this.gaugeAbsoluteDeviation = response.data.absolute_deviation
              this.averageLabels = response.data.labels
              this.currentTimeSpan = response.data.time_span
              this.gaugeKey = this.gaugeKey + 1
            })
      }
    },
    yMin() {
      let tempMin = 300
      const data = this.mixedChartDataSet
      data.forEach(competitor => {
        competitor.data.forEach(x => {
          x < tempMin ? tempMin = x : false
        })
      })
      return (Math.round(tempMin / 10) * 10) - 10
    },
    y0Max() {
      let tempMax = 0
      const data = this.mixedChartDataSet
      data[0].data.forEach(x => {
        x > tempMax ? tempMax = x : false
      })
      return (Math.round(tempMax / 10) * 10) + 10
    },
    y1Max() {
      let tempMax = 0
      const data = this.mixedChartDataSet
      data[1].data.forEach(x => {
        x > tempMax ? tempMax = x : false
      })
      return (Math.round(tempMax / 10) * 10) + 10
    },
    lowerBound() {
      switch(true) {
        case this.gaugeAverage < 180:
          return 0.2
        case this.gaugeAverage >= 180 && this.gaugeAverage < 220:
          return 0.3
        case this.gaugeAverage >= 220:
          return 0.4
      }
    },
    middleBound() {
      switch(true) {
        case this.gaugeAverage < 180:
          return 0.4
        case this.gaugeAverage >= 180 && this.gaugeAverage < 200:
          return 0.6
        case this.gaugeAverage >= 200 && this.gaugeAverage < 220:
          return 0.7
        case this.gaugeAverage >= 220:
          return 0.75
      }
    },
    upperBound() {
      switch(true) {
        case this.gaugeAverage < 180:
          return 0.4
        case this.gaugeAverage >= 180 && this.gaugeAverage < 200:
          return 0.5
        case this.gaugeAverage >= 200 && this.gaugeAverage < 220:
          return 0.6
        case this.gaugeAverage >= 220:
          return 0.7
      }
    }
  },
  computed: {
    mixedChartOptions: function () {
      return {
        title: {
          text: "Averages by game"
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: this.mixedLegend,
          top: '10%'
        },
        toolbox: {
          feature: {
            dataView: {show: true, readOnly: false},
            saveAsImage: {show: true}
          }
        },
        grid: {
          top: '15%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.averageLabels
        },
        yAxis: [
          {
            type: 'value',
            max: this.y0Max(),
          },
          {
            type: 'value',
            max: this.y1Max(),
            splitLine: {
              show: false
            }

          }
        ],
        series: this.mixedChartDataSet
      }
    },
    absoluteDeviationChartOptions: function () {
      return {
        title: {
          text: 'Absolute deviation'
        },
        toolbox: {
          feature: {
            saveAsImage: {show: true}
          }
        },
        series: [
          {
            type: 'gauge',
            max: 50,
            axisLine: {
              lineStyle: {
                width: 18,
                color: [
                  [this.lowerBound(), '#ffd452'],
                  [this.middleBound(), '#8cf7c5'],
                  [0.8, '#ffd452'],
                  [1, '#fd666d']
                ]
              }
            },
            pointer: {
              itemStyle: {
                color: 'inherit'
              }
            },
            axisTick: {
              distance: -30,
              length: 8,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            splitLine: {
              distance: -30,
              length: 30,
              lineStyle: {
                color: '#fff',
                width: 4
              }
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 25,
              itemStyle: {
                borderWidth: 10
              }
            },
            axisLabel: {
              color: '#999',
              distance: 25,
              fontSize: 12
            },
            detail: {
              valueAnimation: true,
              fontSize: 50,
              offsetCenter: [0, '80%'],
              color: 'inherit'
            },
            data: [
              {
                value: this.gaugeAbsoluteDeviation
              }
            ]
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>