<template>
  <v-chart class="chart" :option="option"/>
</template>

<script>
import {use} from "echarts/core";
import {SVGRenderer} from "echarts/renderers";
import {GaugeChart} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart, {THEME_KEY} from "vue-echarts";

use([
  SVGRenderer,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent
]);

export default {
  name: "CustomGaugeChart",
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  props: {
    average: {
      type: Number
    },
    chart_title: {
      type: String
    }
  },
  data() {
    return {
      option: {
        title: {
          text: this.chart_title
        },
        toolbox: {
          feature: {
            saveAsImage: {show: true}
          }
        },
        series: [
          {
            type: 'gauge',
            max: 250,
            progress: {
              show: true,
              width: 18
            },
            axisLine: {
              lineStyle: {
                width: 18
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 5,
              lineStyle: {
                color: '#fff',
                width: 0,
              }
            },
            axisLabel: {
              color: '#999',
              distance: 25,
              fontSize: 12
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 25,
              itemStyle: {
                borderWidth: 10
              }
            },
            detail: {
              valueAnimation: true,
              fontSize: 50,
              offsetCenter: [0, '80%']
            },
            data: [
              {
                value: this.average
              }
            ]
          }
        ]
      }
    };
  },
  methods: {}
};
</script>

<style scoped>

</style>