<template>
  <div>
    <side_nav_main
        :links="localLinks"
        :drawer_is_open="this.$root.$data.store.sideNavOpen"
    ></side_nav_main>
  </div>
</template>

<script>
export default {
  name: "side_nav_outer",
  props: {
    links: {
      type: Array
    }
  },
  data() {
    return {
      localLinks: this.links
    }
  }
}
</script>

<style scoped>

</style>