<template>
  <div>
    <v-text-field v-if="displayGameCount"
                  :label="'Number of games'"
                  type="number"
                  :outlined="true"
                  :id="'block_game_count'"
                  :value="gameCount"
                  :name="'block_game_count'"
    ></v-text-field>
    <v-select v-if="comp_type == null || comp_type == ''"
              :value="competitorType"
              id="block_tournament_competitor_type"
              :label="'Team Scoring Type'"
              :placeholder="'Scoring Type'"
              :items="competitorTypeList"
              :outlined="true"
              :name="'block_tournament[competitor_type]'"
              :hint="'Aggregate is for singles, doubles, etc. Unified is for Bakers, Scotch Doubles, etc.'"
              :error-messages="comp_type_error"
              v-on:change="handleCompetitorTypeChange"
    >
    </v-select>
    <div style="margin-bottom: 15px" v-else>
      <strong>Scoring Type:</strong> {{ compTypeTextOptions[competitorType] }}
    </div>
    <v-expansion-panels v-if="displayAggregateScoreLimit">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Aggregate Scoring Advanced Options
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
              :value="scoredGameLimit"
              type="number"
              :outlined="true"
              :clearable="true"
              :label="'Limit scored games to the top:'"
              :placeholder="'# of games'"
              :name="'block_tournament[scored_game_limit]'"
              :hint="'Leave empty for all scores to count. Based on the number entered, only that many top scores will count towards the team total.'"
              :error-messages="score_limit_error"
          >
          </v-text-field>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "competitor_type_select",
  props: {
    comp_type_error: {
      type: Array
    },
    score_limit_error: {
      type: Array
    },
    comp_type: {
      type: String
    },
    scored_game_limit: {
      type: Number
    },
    display_game_count: {
      type: Boolean
    }
  },
  data() {
    return {
      competitorType: this.comp_type,
      displayAggregateScoreLimit: this.comp_type === 'aggregate_team',
      scoredGameLimit: this.scored_game_limit,
      displayGameCount: this.display_game_count,
      gameCount: null,
      competitorTypeList: [{
        text: 'Aggregate Scoring',
        value: 'aggregate_team'
      },
        {
          text: 'Unified Scoring',
          value: 'unified_team'
        }],
      scoreLimitList: [
        {text: '1', value: 1},
        {text: "2", value: 2},
        {text: "3", value: 3},
        {text: "4", value: 4},
        {text: "5", value: 5},
        {text: "6", value: 6},
      ],
      compTypeTextOptions: {
        "aggregate_team": "Aggregate Team",
        "unified_team": "Unified Team"
      }
    }
  },
  methods: {
    handleCompetitorTypeChange: function (event) {
      this.displayAggregateScoreLimit = event === 'aggregate_team'
    }
  }
}
</script>

<style scoped>

</style>