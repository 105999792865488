<template>
  <div>
    <v-select v-if="displayScoringRuleTypeOptions"
              :label="'Competition Type'"
              :value="scoringRuleType"
              :placeholder="'Competition Type'"
              :items="scoringRuleTypes"
              :outlined="true"
              :name="'scoring_rule[scoring_rule_type]'"
              v-on:change="handleCompetitorTypeChange"
    >
    </v-select>
    <v-select v-if="displayTeamTypeOptions"
              :id="'scoring_rule_team_type_id'"
              :label="'Team Type'"
              :placeholder="'Team Type'"
              :items="teamTypes"
              :outlined="true"
              :name="'scoring_rule[team_type_id]'"
              :clearable="true"
              :error-message="teamTypeErrorMessages"
    >
    </v-select>
    <checkbox_hidden_field
        :label="'Auto register applicable competitors'"
        :id="'scoring_rule_auto_register'"
        :name="'scoring_rule[auto_register]'"
        :value="autoRegister">
    </checkbox_hidden_field>
    <checkbox_hidden_field
        :label="'Default for all divisions'"
        :id="'scoring_rule_tournament_default'"
        :name="'scoring_rule[tournament_default]'"
        :value="tournamentDefault"
        :error_messages="tournamentDefaultErrorMessage"
    >
    </checkbox_hidden_field>
    <div v-if="displayTeamTypeOptions === false">
      <v-checkbox
          v-model="jgQualifier"
          :label="'Junior Gold qualifier'"
          v-on:change="handleJgChange">
      </v-checkbox>
      <input
          :id="'scoring_rule_jg_qualifier'"
          type="hidden"
          :name="'scoring_rule[jg_qualifier]'"
          :value="jgQualifier"
          :key="jgQualifier"
      >
    </div>
    <v-select v-if="jgQualifier"
              :label="'Junior Gold Season'"
              :placeholder="'Select Junior Gold Season'"
              :items="jgSeasons"
              :value="seasonId"
              :outlined="true"
              :name="'scoring_rule[season_id]'"
              :id="'scoring_rule_season_id'"
              :error-message="seasonIdErrorMessages"
    >
    </v-select>
  </div>
</template>

<script>
import Checkbox_hidden_field from "./checkbox_hidden_field";

export default {
  name: "scoring_rule_create_form",
  props: {
    id: {
      type: Number
    },
    scoring_rule_type: {
      type: String
    },
    scoring_rule_types: {
      type: Array
    },
    scoring_rule_types_error_message: {
      type: Array
    },
    team_types: {
      type: Array
    },
    team_type_error_messages: {
      type: Array
    },
    jg_qualifier: {
      type: Boolean
    },
    season_id: {
      type: Number
    },
    season_id_error_messages: {
      type: Array
    },
    jg_seasons: {
      type: Array
    },
    auto_register: {
      type: Boolean
    },
    tournament_default: {
      type: Boolean
    },
    tournament_default_error_message: {
      type: Array
    }


  },
  data() {
    return {
      scoringRuleId: this.id,
      scoringRuleType: this.scoring_rule_type,
      scoringRuleTypes: this.scoring_rule_types,
      scoringRuleTypeErrorMessage: this.scoring_rule_types_error_message || null,
      teamTypes: this.team_types,
      teamTypeErrorMessages: this.team_type_error_messages || null,
      displayScoringRuleTypeOptions: this.id === null && this.team_types.length > 0,
      displayTeamTypeOptions: false,
      jgQualifier: this.jg_qualifier || null,
      seasonId: this.season_id,
      seasonIdErrorMessages: this.season_id_error_messages,
      jgSeasons: this.jg_seasons,
      autoRegister: this.auto_register,
      tournamentDefault: this.tournament_default,
      tournamentDefaultErrorMessage: this.tournament_default_error_message || null
    }
  },
  components: {
    'checkbox_hidden_field': Checkbox_hidden_field
  },
  methods: {
    handleCompetitorTypeChange(event) {
      this.displayTeamTypeOptions = event === 'teams_standard'
    },
    handleJgChange(event) {
      this.jgQualifier = event
    }
  },
}
</script>

<style scoped>

</style>