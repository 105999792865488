const ShiftSquadLaneAssignments = {

    assignAssignmentSchemeHelpListener() {
        const element = document.getElementById('division_scheme')
        element?.addEventListener('change', () => ShiftSquadLaneAssignments.assignmentSchemeFields());
    },

    assignmentSchemeFields() {
        var x = document.getElementById("division_scheme").value;
        var y = ['empty_spots_custom', 'empty_spots_field']
        if (y.includes(x)) {
            document.getElementById("bowlers_per_pair_option").style.display = "none";
            document.getElementById("shuffling_scheme_option").style.display = "none";
            document.getElementById("assignment_sequence_option").style.display = "none";
            document.getElementById("excess_pairs_option").style.display = "none";
            document.getElementById("group_together_option").style.display = "none";

        } else {
            document.getElementById("bowlers_per_pair_option").style.display = "block";
            document.getElementById("shuffling_scheme_option").style.display = "block";
            document.getElementById("assignment_sequence_option").style.display = "block";
            document.getElementById("excess_pairs_option").style.display = "block";
            document.getElementById("group_together_option").style.display = "block";
        }

        if (x === 'empty_spots_custom') {
            document.getElementById("bowlers_per_pair_option").style.display = "block";
        } else {
            document.getElementById("bowlers_per_pair_option").style.display = "none";
        }

    }
}

export default ShiftSquadLaneAssignments