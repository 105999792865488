<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="localStandings"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :items-per-page="10"
        item-key="bowler_fullname"
        show-expand
        :search="search"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
            clearable
        ></v-text-field>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <p v-for="appearance in item.appearances" class="sub-record">
            {{
              `${appearance.date} | ${appearance.tournament_title} | ${appearance.competition_title} | ${appearance.points}pts.`
            }}
          </p>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "bowler_championships_table",
  props: {
    standings: {
      type: Array
    },
  },
  data() {
    return {
      expanded: [],
      singleExpand: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'bowler_fullname',
        },
        {
          text: 'Events',
          value: 'event_count',
          align: 'end'
        },
        {
          text: 'Points',
          value: 'points',
          align: 'end'
        },
        {
          text: '[ - ]',
          value: 'points_back',
          align: 'end'
        },
      ],
      search: '',
      localStandings: this.standings
    }
  },
}
</script>

<style scoped>

</style>