import EventListeners from "./event_listeners";


const SquadTournament = {
    assignSquaCutlineBlocksListener() {
        EventListeners.assignByClassName('squad-cutline-button', 'change', () => SquadTournament.squadCutlineBlocks())
    },

    squadCutlineBlocks() {
        var x = document.getElementById("squad_block_group_opening_blocks").checked;
        if (x == true) {
            document.getElementById("squad_tournament_block_tournament_id").disabled = true;
            document.getElementById("squad_tournament_block_tournament_id").selectedIndex = 0;
        } else {
            document.getElementById("squad_tournament_block_tournament_id").disabled = false;
        }
    }
}

export default SquadTournament
