import EventListeners from "./event_listeners";
import ShiftTile from "./shift_tile";


const BlockTournaments = {

    assignAdminButtonListeners() {
        const adminButtons = document.getElementsByClassName('block-advanced-options-button')
        if (adminButtons) {
            for (let i = 0; i < adminButtons.length; i++) {
                const element = adminButtons[i]
                const blockId = element.dataset.blockid;
                element.addEventListener('click', () => BlockTournaments.displayBlockAdminPanel(blockId));
            }
        }
    },

    assignSidePanelCloseListeners() {
        EventListeners.assignByClassName('side-panel-close', 'click', () => BlockTournaments.closeAllPanels())
    },

    assignBlockHandicapListener() {
        const element = document.getElementById('block_tournament_handicap_block');
        if (element) {
            element.addEventListener('change', () => BlockTournaments.handicapType());
        }
    },

    assignCompetitorTypeListener() {
        const element = document.getElementById('block_tournament_competitor_type');
        if (element) {
            element.addEventListener('change', () => BlockTournaments.competitorType());
        }
    },

    assignHandicapListener() {
        const element = document.getElementById('block_tournament_handicap_block');
        if (element) {
            element.addEventListener('change', () => BlockTournaments.handicapType());
        }
    },

    competitorType() {
        let x = document.getElementById("block_tournament_competitor_type").value;
        let scoring_rule = document.getElementById("scoring_rule_block").value;

        if (scoring_rule == 'true') {
            if (x == 'aggregate_team') {
                document.getElementById("comp_type_help_aggregate_team").style.display = "block";
                document.getElementById("comp_type_help_unified_team").style.display = "none";
            }
            if (x == 'unified_team') {
                document.getElementById("comp_type_help_aggregate_team").style.display = "none";
                document.getElementById("comp_type_help_unified_team").style.display = "block";
            }
        } else {
            if (x == 'individual') {
                document.getElementById("comp_type_help_individual").style.display = "block";
                document.getElementById("team_type_option").style.display = "none";
                document.getElementById("comp_type_help_aggregate_team").style.display = "none";
                document.getElementById("comp_type_help_unified_team").style.display = "none";
            } else {
                document.getElementById("team_type_option").style.display = "block";
            }

            if (x == 'aggregate_team') {
                document.getElementById("comp_type_help_individual").style.display = "none";
                document.getElementById("comp_type_help_aggregate_team").style.display = "block";
                document.getElementById("comp_type_help_unified_team").style.display = "none";
            }
            if (x == 'unified_team') {
                document.getElementById("comp_type_help_individual").style.display = "none";
                document.getElementById("comp_type_help_aggregate_team").style.display = "none";
                document.getElementById("comp_type_help_unified_team").style.display = "block";
            }
        }
    },

    handicapType() {
        if (document.getElementById("block_tournament_handicap_block").checked) {
            document.getElementById("test_handicap_fields").style.display = "block";
        } else {
            document.getElementById("test_handicap_fields").style.display = "none"
        }

    },

    synchronizedBlockType(block_type) {
        let synch_dropdown = document.getElementById('synch_block_container')
        if (block_type === 'standard_synchronized') {
            synch_dropdown.style.display = 'block'
        } else {
            synch_dropdown.style.display = 'none'
        }
    },

    displayBlockAdminPanel(blockTournamentId) {
        ShiftTile.closeAllPanels()
        let panelId = "block-admin-" + blockTournamentId
        let sidePanel = document.getElementById(panelId)
        if (sidePanel) {
            sidePanel.style.width = '350px'
        }
    },

    closeAllPanels() {
        let panels = document.getElementsByClassName('side-panel-container')
        for (let i = 0; i < panels.length; i++) {
            BlockTournaments.closeSidePanel(panels[i].id)
        }
    },


    closeSidePanel(elementID) {
        let theContainer = document.getElementById(elementID)
        theContainer.style.width = "0px"
    },

}

export default BlockTournaments;