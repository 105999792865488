<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="competitors"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                label="Search"
                class="filter-field"
                clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select :items="divisionFilterList"
                      v-model="divisionFilterValue"
                      label="Division"
                      clearable
                      class="filter-field"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="props">
        <a :href="getDeleteLink(props.item.id)"
           data-confirm="Are you sure?"
           data-method="delete"
           class="delete-icon"><i class="fa fa-trash-alt"></i></a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "competitor_squad_tournaments_table",
  props: {
    competitors: {
      type: Array
    },
    scoring_rule_id: {
      type: Number
    },
    divisions: {
      type: Array
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Competitor',
          align: 'start',
          value: 'competitor_name',
        },
        {
          text: 'Division',
          value: 'division_name',
          align: 'center',
          filter: this.divisionFilter
        },
        {text: '',
          value: 'edit',
          align: 'end',
          sortable: false}
      ],
      search: '',
      divisionFilterValue: '',
      divisionFilterList: this.divisions,
    }
  },
  methods: {

    getDeleteLink(id){
      return '/competitor_squad_tournaments/' + id
    },
    divisionFilter(value) {
      if (!this.divisionFilterValue) {
        return true
      } else {
        return value === this.divisionFilterValue
      }
    },
  },
  computed: {}
}
</script>

<style scoped>

</style>