
const ScoringRules = {

    assignJGQualifierSeasonListener() {
        const element = document.getElementById('scoring_rule_jg_qualifier')
        element?.addEventListener('change', () => ScoringRules.jgQualifierSeason2())
    },

    assignScoringRuleTypeListener() {
        const element = document.getElementById('scoring_rule_scoring_rule_type')
        element?.addEventListener('change', () => ScoringRules.teamTTSR())
    },

    assignToggleScoringDetailsListener() {
        const elements = document.getElementsByClassName('detail-icon');
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            const competitorId = element.dataset.competitorId;
            element.addEventListener('click', () => ScoringRules.toggleScoringDetails(competitorId));
        }
    },


    teamTTSR() {
        let x = document.getElementById("scoring_rule_scoring_rule_type").value;
        if (x == 'individuals_standard') {
            document.getElementById("scoring_rule_team_type_options").style.display = "none";
        } else {
            document.getElementById("scoring_rule_team_type_options").style.display = "block";
        }

    },


    jgQualifierSeason2() {
        if (document.getElementById("scoring_rule_jg_qualifier").checked) {
            document.getElementById("scoring_rule_jg_season").style.display = "inline";
        } else {
            document.getElementById("scoring_rule_jg_season").style.display = "none";
        }

    },


    toggleScoringDetails(competitor_id) {
        const expansionPanel = document.getElementById('scoring-details-' + competitor_id);
        const expandIcon = document.getElementById('expand-details-icon-' + competitor_id);
        const collapseIcon = document.getElementById('collapse-details-icon-' + competitor_id);


        if (expansionPanel.style.display == 'none') {
            expansionPanel.style.display = 'block';
            expandIcon.style.display = 'none';
            collapseIcon.style.display = 'block';
        } else {
            expansionPanel.style.display = 'none'
            expandIcon.style.display = 'block';
            collapseIcon.style.display = 'none';
        }
    }

}

export default ScoringRules