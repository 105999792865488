<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="divisions"
        :items-per-page="10"
        class="elevation-1"
    >
      <template v-slot:item.edit="props">
        <a :href="getEditLink(props.item.id)" class="bulk-action-link-button">Edit</a>
        <a :href="getDeleteLink(props.item.id)"
           data-confirm="Are you sure?"
           data-method="delete"
           class="delete-icon"><i class="fa fa-trash-alt"></i></a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "shift_divisions_table",
  props: {
    divisions: {
      type: Array
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Division',
          align: 'start',
          value: 'division_name'
        },
        {
          text: 'Play order',
          align: 'start',
          value: 'play_order'
        },
        {
          text: 'Biased',
          align: 'start',
          value: 'biased'
        },
        {
          text: '',
          align: 'end',
          value: 'edit',
          sortable: false
        }
      ],
    }
  },
  methods: {
    getEditLink(id) {
      return '/division_shift_squads/' + id + '/edit'
    },
    getDeleteLink(id) {
      return '/division_shift_squads/' + id
    }
  }
}
</script>

<style scoped>

</style>