<template>
  <div style="max-width: 600px">
    <v-select
        :label="'Block Type'"
        :placeholder="'Block Type'"
        :items="blockTypes"
        :value="localValue"
        :outlined="true"
        :name="'block_type'"
        :id="'block_type_hidden_val'"
        v-on:change="displaySynchBlockOptions"
    >
    </v-select>
    <div>
      <p>{{ helpMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "block_type",
  props: {
    block_types: {
      type: String
    }
  },
  data() {
    return {
      blockTypes: JSON.parse(this.block_types),
      localValue: 'standard',
      helpMessage: 'A conventional block consisting of games bowled in sequence.'
    }
  },
  methods: {
    setHelpMessage: function (event) {
      switch (event) {
        case 'cutline':
          this.helpMessage = 'A block used to reduce the field, allowing you to defined advancement rules.'
          break
        case 'matchplay_scratch_single_elimination':
          this.helpMessage = "A standard single elimination bracket, seeded using conventional top seed vs. bottom seed format. BowlMetrix creates a cutline for you based on the depth of the bracket."
          break
        case 'matchplay_stepladder':
          this.helpMessage = 'A matchplay format where the bottom two seeds compete head-to-head, with the winner advancing to bowl against the next highest seed, and so on until the final championship match against the top seed. BowlMetrix creates a cutline for you based on the height of the stepladder.'
          break
        case 'standard_synchronized':
          this.helpMessage = 'A standard block but where the scores are actually synchronized with another block in another competition.'
        default:
          this.helpMessage = 'A conventional block consisting of games bowled in sequence.'
      }
    },
    displaySynchBlockOptions: function (event) {
      this.setHelpMessage(event)
      let targetElement = document.getElementById('synchable-blocks-container')

      let testResult = event === 'standard_synchronized'
      if (testResult) {
        targetElement.style.display = 'block'
      } else {
        targetElement.style.display = 'none'
      }
    }
  }
}
</script>

<style scoped>

</style>