const SubTab = {

    addListeners() {
        SubTab.assignSubTabClickEvent();
        SubTab.assignSubtabMenuClickEvent()
    },


    openSubTabs(entityType, tabType, entityId) {
        SubTab.closeAllSubtabMenus()
        // console.log("HIT openSubTabs", entityType, tabType, entityId)
        //hide all of the content
        let x = document.getElementsByClassName('subtab-content-' + entityType + '-' + entityId);
        // console.log("Content: ", x.length)
        for (var i = 0; i < x.length; i++) {
            x[i].style.display = "none";
        }

        //expose the chosen content
        document.getElementById('subtab-content-' + entityType + '-' + tabType + '-' + entityId).style.display = "block";

        //hide all of the tabs
        x = document.getElementsByClassName('subtab-tab-' + entityType + '-' + entityId);
        for (i = 0; i < x.length; i++) {
            x[i].className = x[i].className.replace(" subtab-active", " subtab-inactive")
        }

        //expose the chosen tab
        const targetButton = document.getElementById('subtab-tab-' + entityType + '-' + tabType + '-' + entityId)
        targetButton.classList.remove('subtab-inactive')
        targetButton.classList.add('subtab-active')
    },


    toggleSubTabMenu(entityType, entityId) {
        SubTab.closeAllSubtabMenus()

        //Toggle the specific menu
        const shiftMenuDropDown = document.getElementById("subtab-menu-" + entityType + '-' + entityId)

        if (shiftMenuDropDown.style.display === "block") {
            shiftMenuDropDown.style.display = "none"
        } else {
            shiftMenuDropDown.style.display = "block"
        }
    },

    closeAllSubtabMenus() {
        const shiftMenus = document.getElementsByClassName("subtab-menu-dropdown")
        for (let i = 0; i < shiftMenus.length; i++) {
            shiftMenus[i].style.display = "none"
        }
    },

    displaySubTabByMenu(entityType, tabType, entityId) {
        SubTab.toggleSubTabMenu(entityType, entityId)
        SubTab.openSubTabs(entityType, tabType, entityId)
    },


    assignSubTabClickEvent() {
        const x = document.getElementsByClassName('subtab-button');

        if (x.length > 0) {
            for (let i = 0; i < x.length; i++) {
                const subtabType = x[i].dataset.subtabType;
                const subtabTab = x[i].dataset.subtabTab;
                const subtabId = x[i].dataset.subtabId;
                x[i].addEventListener('click', () => SubTab.openSubTabs(subtabType, subtabTab, subtabId));
            }
        }
    },

    assignSubtabMenuClickEvent() {
        const subMenus = document.getElementsByClassName('menu-button');
        if (subMenus.length > 0) {
            for (let i = 0; i < subMenus.length; i++) {
                const submenuType = subMenus[i].dataset.submenuType;
                const submenuId = subMenus[i].dataset.submenuId;
                subMenus[i].addEventListener('click', () => SubTab.toggleSubTabMenu(submenuType, submenuId));
            }
        }
    }
}


export default SubTab
