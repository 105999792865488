<template>
  <div id='product-component-1660256996133'></div>
</template>

<script>
export default {
  name: "shopify_buy_button",
  updated() {
    this.kickOff()
  },
  methods: {
    kickOff() {
      var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          ShopifyBuyInit();
        } else {
          loadScript();
        }
      } else {
        loadScript();
      }
      function loadScript() {
        var script = document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        script.onload = ShopifyBuyInit;
      }
      function ShopifyBuyInit() {
        var client = ShopifyBuy.buildClient({
          domain: 'georgia-youth-bowlers-tour.myshopify.com',
          storefrontAccessToken: '95b48f68866857c419c251462a702919',
        });
        ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('product', {
            id: '7512836538521',
            node: document.getElementById('product-component-1660256996133'),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
              "product": {
                "styles": {
                  "product": {
                    "@media (min-width: 601px)": {
                      "max-width": "calc(25% - 20px)",
                      "margin-left": "20px",
                      "margin-bottom": "50px"
                    }
                  },
                  "button": {
                    ":hover": {
                      "background-color": "#e63e00"
                    },
                    "background-color": "#ff4500",
                    ":focus": {
                      "background-color": "#e63e00"
                    }
                  }
                },
                "text": {
                  "button": "Add to cart"
                }
              },
              "productSet": {
                "styles": {
                  "products": {
                    "@media (min-width: 601px)": {
                      "margin-left": "-20px"
                    }
                  }
                }
              },
              "modalProduct": {
                "contents": {
                  "img": false,
                  "imgWithCarousel": true,
                  "button": false,
                  "buttonWithQuantity": true
                },
                "styles": {
                  "product": {
                    "@media (min-width: 601px)": {
                      "max-width": "100%",
                      "margin-left": "0px",
                      "margin-bottom": "0px"
                    }
                  },
                  "button": {
                    ":hover": {
                      "background-color": "#e63e00"
                    },
                    "background-color": "#ff4500",
                    ":focus": {
                      "background-color": "#e63e00"
                    }
                  }
                },
                "text": {
                  "button": "Add to cart"
                }
              },
              "option": {},
              "cart": {
                "styles": {
                  "button": {
                    ":hover": {
                      "background-color": "#e63e00"
                    },
                    "background-color": "#ff4500",
                    ":focus": {
                      "background-color": "#e63e00"
                    }
                  }
                },
                "text": {
                  "total": "Subtotal",
                  "button": "Checkout"
                }
              },
              "toggle": {
                "styles": {
                  "toggle": {
                    "background-color": "#ff4500",
                    ":hover": {
                      "background-color": "#e63e00"
                    },
                    ":focus": {
                      "background-color": "#e63e00"
                    }
                  }
                }
              }
            },
          });
        });
      }
    },

  }
}
</script>

<style scoped>

</style>