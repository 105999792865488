<template>
  <div>
    <v-select
        :label="'Assignment scheme'"
        :placeholder="'Assignment scheme'"
        :items="divisionSchemes"
        :outlined="true"
        :name="'division_scheme'"
        :id="'division_scheme'"
        :value="divisionSchemeValue"
        v-on:change="handleDivisionSchemeChange"
    >
    </v-select>
    <v-select v-if="displayAutoAssignOptions"
              :label="'Shuffling scheme'"
              :placeholder="'Shuffling scheme'"
              :items="localShufflingSchemes"
              :outlined="true"
              :name="'shuffle_scheme'"
              :id="'shuffle_scheme'"
              :value="shuffleFieldValue"
    >
    </v-select>
    <v-select v-if="displayAggregateTeamOptions && displayAutoAssignOptions"
              :label="'Teammate Scheme'"
              :placeholder="'Teammate scheme'"
              :items="teamSchemes"
              :outlined="true"
              :name="'team_scheme'"
              :id="'team_scheme'"
              :value="teamSchemeValue"
    >
    </v-select>
    <v-text-field v-if="displayAggregateTeamOptions && displayAutoAssignOptions"
                  :id="'team_limit'"
                  :name="'team_limit'"
                  type="number"
                  :label="'Team roster limit'"
                  :outlined="true"
                  :value="teamLimitValue"
    >
    </v-text-field>
    <v-select v-if="displayAutoAssignOptions"
              :label="'Assignment sequence will start on lane'"
              :placeholder="'Assignment sequence will start on lane'"
              :items="startingLanes"
              :outlined="true"
              :name="'start_assignments'"
              :id="'start_assignments'"
              :value="startingLaneValue"
    >
    </v-select>
    <v-text-field v-if="displayBowlersPerPairOption"
                  :id="'bowlers_per_pair'"
                  :name="'bowlers_per_pair'"
                  type="number"
                  :label="'Bowlers per pair'"
                  :outlined="true"
                  :value="bowlersPerPairValue"
    >
    </v-text-field>
    <checkbox_hidden_field
        :key="'alternating'"
        :label="'Alternating position letters'"
        :id="'alternating'"
        :name="'alternating'"
        :value="alternatingPositionsValue">
    </checkbox_hidden_field>
    <checkbox_hidden_field v-if="displayAutoAssignOptions"
                           :key="'remove_excess_pairs'"
                           :label="'Remove excess lane pairs'"
                           :id="'remove_excess_pairs'"
                           :name="'remove_excess_pairs'"
                           :value="removeExcessPairsValue">
    </checkbox_hidden_field>
    <checkbox_hidden_field v-if="displayAutoAssignOptions"
                           :key="'honor_grouptogether'"
                           :label="'*Honor bowler grouping requests'"
                           :id="'honor_grouptogether'"
                           :name="'honor_grouptogether'"
                           :value="honorGroupingRequestsValue">
    </checkbox_hidden_field>
  </div>
</template>

<script>
export default {
  name: "lane_assign_options",
  props: {
    starting_lanes: {
      type: String
    },
    saved_preferences: {
      type: Object
    },
    aggregate_team: {
      type: Boolean
    },
    team_limit: {
      type: Number
    },
    cohorts_exist: {
      type: Boolean
    }
  },
  data() {
    return {
      startingLanes: JSON.parse(this.starting_lanes),
      savedPreferences: this.saved_preferences,
      standardShufflingSchemes: [
        {text: 'Registration order', value: 'registration_order'},
        {text: 'Shuffle entire field', value: 'shuffle_field'},
        {text: 'Shuffle within pair', value: 'shuffle_pair'},
        {text: 'Reverse entire field', value: 'reverse_registration_order'},
        {text: 'Reverse within pair', value: 'reverse_pair'},
      ],
      cohortShufflingSchemes: [
        {text: 'Saved cohort order', value: 'registration_order'},
        {text: 'Shuffle within pair', value: 'shuffle_pair'},
        {text: 'Reverse within pair', value: 'reverse_pair'},
      ],
      teamSchemes: [
        {text: 'Lane exclusive by team', value: 'lane_exclusive'},
        {text: 'Pair exclusive by team', value: 'pair_exclusive'},
        {text: 'Not grouped by team', value: 'unexclusive'}
      ],
      displayAggregateTeamOptions: this.aggregate_team,
      displayBowlersPerPairOption: ['empty_spots_field', 'empty_spots_custom'].includes(this.saved_preferences?.division_scheme),
      displayAutoAssignOptions: !['empty_spots_field', 'empty_spots_custom'].includes(this.saved_preferences?.division_scheme),
      cohortsExist: this.cohorts_exist,
      localDivisionScheme: null,
      localShufflingSchemes: null
    }
  },
  methods: {
    handleDivisionSchemeChange: function (event) {
      this.displayAutoAssignOptions = !['empty_spots_field', 'empty_spots_custom'].includes(event);
      this.displayBowlersPerPairOption = event == 'empty_spots_custom';
      console.log('localDivisionScheme: ', event)
      if (event == 'cohort_grouped') {
        this.localShufflingSchemes = this.cohortShufflingSchemes
      } else {
        this.localShufflingSchemes = this.standardShufflingSchemes
      }
    },
    shufflingSchemes: function () {
      if (this.localDivisionScheme == 'cohort_grouped') {
        return this.cohortShufflingSchemes
      } else {
        return this.standardShufflingSchemes
      }
    }
  },
  computed: {
    divisionSchemes: function () {
      const schemes = [
        {text: 'Grouped by Division, biased lane loading', value: 'order_by_division_biased'},
        {text: 'Grouped by Division', value: 'order_by_division'},
        {text: 'Mixed Field', value: 'mixed_field'},
        {text: 'Empty spots - Field Count', value: 'empty_spots_field'},
        {text: 'Empty spots - Custom', value: 'empty_spots_custom'},
      ]
      if (this.cohortsExist) {
        schemes.push({text: 'Grouped by cohorts', value: 'cohort_grouped'})
      }
      if (this.displayAggregateTeamOptions) {
        return schemes.slice(1)
      }
      return schemes
    },
    divisionSchemeValue: function () {
      if (this.savedPreferences?.division_scheme) {
        this.localShufflingSchemes = this.savedPreferences.division_scheme == 'cohort_grouped' ? this.cohortShufflingSchemes : this.standardShufflingSchemes
        return this.savedPreferences.division_scheme
      } else {
        return 'order_by_division'
      }
    },
    shuffleFieldValue: function () {
      if (this.savedPreferences?.shuffle_scheme) {
        return this.savedPreferences.shuffle_scheme
      } else {
        return 'registration_order'
      }
    },
    startingLaneValue: function () {
      if (this.savedPreferences?.start_assignments && this.startingLanes.includes(this.savedPreferences?.start_assignments)) {
        return this.savedPreferences?.start_assignments
      } else {
        return this.startingLanes[0]
      }
    },
    bowlersPerPairValue: function () {
      return this.savedPreferences?.bowlers_per_pair ? this.savedPreferences.bowlers_per_pair : 0
    },
    alternatingPositionsValue: function () {
      return this.savedPreferences?.alternating != null ? this.savedPreferences.alternating : true
    },
    removeExcessPairsValue: function () {
      return this.savedPreferences?.remove_excess_pairs != null ? this.savedPreferences.remove_excess_pairs : true
    },
    honorGroupingRequestsValue: function () {
      return this.savedPreferences?.honor_group_together != null ? this.savedPreferences.honor_group_together : true
    },
    teamSchemeValue: function () {
      return this.savedPreferences?.team_scheme != null ? this.savedPreferences.team_scheme : 'lane_exclusive'
    },
    teamLimitValue: function () {
      return this.savedPreferences?.team_limit != null ? this.savedPreferences.team_limit : this.team_limit ? this.team_limit : null
    }
  }
}
</script>

<style scoped>

</style>