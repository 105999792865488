<template>
  <div>
    <v-radio-group v-model="showCountOption" v-on:change="handleRadioChange">
      <v-radio :label="'Cut field by count'" :value="true"></v-radio>
      <v-text-field v-if="showCountOption"
                    :label="'Cutline count'"
                    :value="cutlineCount"
                    :hint="'Enter the count in whole numbers'"
                    :outlined="true"
                    :id="'block_tournament_cutline_count'"
                    :name="'block_tournament[cutline_count]'"
                    :error-messages="cutlineCountErrorMessage"
      >
      </v-text-field>
      <v-radio :label="'Cut field by %'" :value="false"></v-radio>
      <v-text-field v-if="!showCountOption"
                    label="Cutline percentage"
                    :value="cutlinePercentage"
                    :hint="'Enter the percent in whole numbers'"
                    :outlined="true"
                    :id="'block_tournament_cutline_percent'"
                    :name="'block_tournament[cutline_percent]'"
                    :error-messages="cutlinePercentageErrorMessage"
      >
      </v-text-field>
      <input v-if="!showCountOption"
          :id="'block_tournament_cutline_count'"
          type="hidden"
          :name="'block_tournament[cutline_count]'"
          :value="null"
      >
      <input v-if="showCountOption"
             :id="'block_tournament_cutline_percent'"
             type="hidden"
             :name="'block_tournament[cutline_percent]'"
             :value="null"
      >
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "cutline_form",
  props: {
    cutline_count: {
      type: Number
    },
    cutline_count_error_message: {
      type: Array
    },
    cutline_percentage: {
      type: Number
    },
    cutline_percentage_error_message: {
      type: Array
    },
  },
  data() {
    return {
      cutlinePercentage: this.cutline_percentage,
      cutlinePercentageErrorMessage: this.cutline_percentage_error_message,
      cutlineCount: this.cutline_count,
      cutlineCountErrorMessage: this.cutline_count_error_message,
      showCountOption: this.cutline_count > 0
    }
  },
  methods: {
    handleRadioChange: function(event){
      event ? this.cutlinePercentage = 0 : this.cutlineCount = 0
    }
  }
}
</script>

<style scoped>

</style>