import { render, staticRenderFns } from "./competitor_squad_tournaments_table.vue?vue&type=template&id=619d1344&scoped=true&"
import script from "./competitor_squad_tournaments_table.vue?vue&type=script&lang=js&"
export * from "./competitor_squad_tournaments_table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "619d1344",
  null
  
)

export default component.exports