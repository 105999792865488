<template>
  <v-data-table
      :headers="getHeaders()"
      :items="qualifications"
      :items-per-page="10"
      :search="search"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
          clearable
      ></v-text-field>
    </template>
    <template v-slot:item.competition_title="props">
      <div v-if="props.item.competition_title">
        <a :href="getTournamentResultsLink(props.item)">{{ props.item.competition_title }}</a>
      </div>
    </template>
    <template v-slot:item.edit="props">
      <div v-if="!props.item.tournament_id">
        <a class="bulk-action-link-button white-link" :href="getEditQualifierLink(props.item)">Edit</a>
      </div>
    </template>
  </v-data-table>

</template>

<script>
export default {
  name: "bowler_profile_qualifications_table",
  props: {
    qualifications: {
      type: Array
    },
    admin: {
      type: Boolean
    }
  },
  data() {
    return {
      search: '',
      passed_organizer_id: this.organizer_id
    }
  },
  methods: {
    getEditQualifierLink(value) {
      return '/qualifiers/' + value.id + '/edit?redirect_target=bowler_profile'
    },
    getTournamentResultsLink(value) {
      return '/tournament_results/' + value.tournament_id
    },
    getHeaders() {
      const temp_headers = [
        {
          text: 'Season',
          value: 'season_title'
        },
        {
          text: 'Tournament',
          value: 'competition_title',
          align: 'start'
        },
        {
          text: 'Division',
          value: 'division_name',
          align: 'center'
        },
        {
          text: 'Note',
          value: 'note',
          align: 'start'
        }
      ]
      if (this.admin) {
        temp_headers.push({
          text: '',
          value: 'edit',
          align: 'center',
          sortable: false
        })
      }
      return temp_headers
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>