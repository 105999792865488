<template>
  <div id="paypal-button-container"></div>
</template>

<script>

import axios from "axios";

export default {
  name: "paypal_poc",
  props: {
    price: {
      type: String
    },
    organizer_id: {
      type: Number
    },
    handle_success: {
      type: Function
    },
    handle_cancel: {
      type: Function
    },
    handle_error: {
      type: Function
    },
    user_id: {
      type: Number
    },
    quantity: {
      type: Number
    }
  },
  data() {
    return {
      organizerId: this.organizer_id,
      userId: this.user_id,
      bowlMetrixApi: process.env.VUE_APP_API_HOST + '/orders'
    }
  },
  mounted() {
    const paypalButtonsComponent = paypal.Buttons({
      // optional styling for buttons
      // https://developer.paypal.com/docs/checkout/standard/customize/buttons-style-guide/
      style: {
        color: "gold",
        shape: "rect",
        layout: "vertical"
      },

      // set up the transaction
      createOrder: (data, actions) => {
        // pass in any options from the v2 orders create call:
        // https://developer.paypal.com/api/orders/v2/#orders-create-request-body
        const createOrderPayload = {
          purchase_units: [
            {
              amount: {
                value: this.price
              }
            }
          ]
        };

        return actions.order.create(createOrderPayload);
      },

      // finalize the transaction
      onApprove: (data, actions) => {
        const captureOrderHandler = (details) => {
          this.handleTransactionResponse(details)
        };

        return actions.order.capture().then(captureOrderHandler);
      },
      onCancel: () => {
        this.handle_cancel()
      },
      // handle unrecoverable errors
      onError: (err) => {
        const errorMessage = 'An error prevented the buyer from checking out with PayPal'
        console.error(errorMessage);
        this.handle_error(errorMessage)
      }
    });

    paypalButtonsComponent
        .render("#paypal-button-container")
        .catch((err) => {
          console.error('PayPal Buttons failed to render');
        });
  },
  methods: {
    handleTransactionResponse(payload) {
      if (payload.status === "COMPLETED") {
        this.postSuccess(payload)
        this.handle_success(payload)
      }
    },
    postSuccess(payload) {
      const order = {
        user_id: this.userId,
        organizer_id: this.organizerId,
        amount_cents: this.price,
        quantity: this.quantity,
        status: payload.status,
        transaction_number: payload.purchase_units[0].payments.captures[0].id,
        transaction_details: JSON.stringify(payload)
      }
      const newPayload = {order: order}

      axios.post(this.bowlMetrixApi, newPayload,
          {
            headers: {
              'Content-Type': 'application/json',
            }
          })
          .then(response => {
            console.log("Response: ", response)
          })
    }
  }

}
</script>

<style scoped>

</style>