<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="bowlers"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="300"
    >
      <template v-slot:item.name="props">
        <a :href="getBowlerProfileLink(props.item)">{{props.item.name}}</a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "bowler_profile_search_table",
  props: {
    bowlers: {
      type: Array
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        {
          text: 'USBC#',
          value: 'usbc_number',
          align: 'end'
        },
      ],
      search: '',
    }
  },
  methods: {
    getBowlerProfileLink(value) {
      return '/bowler_profiles/' + value.id
    }
  }
}
</script>

<style scoped>

</style>