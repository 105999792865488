<template>
  <div class="purchase-credit-container">
    <div class="transaction-container">
      <v-alert
          v-model="displayAlert"
          :type="alertType"
          :dismissible="true"
          @input="handleAlertClose"
          class="transaction-alert"
      >
        {{ alertMessage }}
      </v-alert>
      <h1>Purchase Tournament Credits</h1>
      <div v-if="displayOrderOptions" class="purchase-fields-container">
        <div class="select-container">
          <v-select :items="organizers"
                    v-model="organizerId"
                    label="Organizer"
          ></v-select>
        </div>
        <div class="select-container">
          <v-select :items="quantityOptions"
                    v-model="quantity"
                    label="Quantity"
                    align="center"
          ></v-select>
        </div>
        <div class="price-container">
          {{ priceForDisplay() }}
        </div>

        <div class="paypal-component-container" v-if="organizerId">
          <paypal_poc
              :price="priceInCents()"
              :quantity="quantity"
              :organizer_id="organizerId"
              :user_id="userId"
              :handle_success="handleSuccess"
              :handle_cancel="handleCancel"
              :handle_error="handleError"
          >
          </paypal_poc>
        </div>
        <div v-else class="payment-space">
          Payment options will display after an organizer is selected.
        </div>
      </div>
      <div v-if="displaySuccess">
        <v-spacer></v-spacer>
        <div>
          <div class="transaction-details-container">
            <div class="transaction-detail">
              {{ payerName }}
            </div>
            <div class="transaction-detail">
              {{ payerEmail }}
            </div>
            <div class="transaction-detail">
              Transaction: {{ transactionId }}
            </div>
          </div>
          <p>A tournament credit has been applied to your chosen organization.</p>
          <v-spacer></v-spacer>
          <router-link :to="'/organizer_configurations'">Return to Organizer Dashboard.</router-link>
          <router-link :to="'/organizer_balances'">Review account activity.</router-link>
        </div>
      </div>
      <div class="cancel-container">
        <router-link class="cancel-main cancel-button" :to="'/organizer_configurations'">Cancel</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "purchase_credit",
  props: {
    user_id: {
      type: Number
    },
    organizers: {
      type: Array
    }
  },
  data() {
    return {
      userId: this.user_id,
      payPalURL: 'https://www.paypal.com/sdk/js?client-id=' + process.env.PAYPAL_CLIENT_ID + '&currency=USD&intent=capture',
      organizerOptions: this.organizers,
      organizerId: null,
      priceCents: 0,
      quantity: 1,
      basePrice: 50,
      displayOrderOptions: true,
      displaySuccess: false,
      displayAlert: false,
      alertType: 'success',
      alertMessage: 'Payment succeeded!',
      payerName: null,
      payerEmail: null,
      transactionId: null,
      quantityOptions: [
        {text: '1', value: 1},
        {text: '2', value: 2},
        {text: '3', value: 3},
        {text: '4', value: 4},
        {text: '5', value: 5},
        {text: '6', value: 6},
        {text: '7', value: 7},
        {text: '8', value: 8},
        {text: '9', value: 9},
        {text: '10', value: 10},
        {text: '11', value: 11},
        {text: '12', value: 12},
        {text: '13', value: 13},
        {text: '14', value: 14},
        {text: '15', value: 15},
      ]
    }
  },
  methods: {
    handleAlertClose() {
      this.displayAlert = false
    },
    handleSuccess(details) {
      const {email_address, name} = details.payer
      this.payerName = "" + name.given_name + " " + name.surname
      this.payerEmail = email_address
      this.transactionId = details.id
      this.displayOrderOptions = false
      this.alertType = 'success'
      this.alertMessage = 'Payment succeeded!'
      this.displayAlert = true
      this.displaySuccess = true
    },
    handleCancel() {
      this.alertType = 'info'
      this.alertMessage = 'Transaction cancelled by user.'
      this.displayAlert = true
    },
    handleError(error) {
      this.alertType = 'error'
      this.alertMessage = error
      this.displayAlert = true
    },
    priceForDisplay() {
      return "$" + (this.quantity * this.basePrice) + ".00"
    },
    priceInCents() {
      return "" + (this.quantity * this.basePrice)
    }
  },
  mounted(){
    if (document.getElementById('my-paypal-src')) return; // was already loaded
    const scriptTag = document.createElement("script");
    scriptTag.src = this.payPalURL;
    scriptTag.id = "my-paypal-src";
    document.getElementsByTagName('head')[0].appendChild(scriptTag);
  },
  computed: {

  }
}
</script>

<style scoped>

</style>