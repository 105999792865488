import { render, staticRenderFns } from "./season_averages_table.vue?vue&type=template&id=bba66198&scoped=true&"
import script from "./season_averages_table.vue?vue&type=script&lang=js&"
export * from "./season_averages_table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bba66198",
  null
  
)

export default component.exports