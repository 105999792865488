<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="organizers"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="300"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
            clearable
        ></v-text-field>
      </template>
      <template v-slot:item.organizer_name="props">
        <a :href="getSeasonResultsLink(props.item)">{{props.item.organizer_name}}</a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "results_organizers_table",
  props: {
    organizers: {
      type: Array
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'organizer_name',
        },
        {
          text: 'Events',
          value: 'tournament_count',
          align: 'end'
        },
      ],
      search: '',
    }
  },
  methods: {
    getSeasonResultsLink(value) {
      return '/organizer_results/' + value.id
    }
  }
}
</script>

<style scoped>

</style>