window.onclick = function (event) {
    var isMatch = event.target.matches('.shift-menu-icon')
    if (!isMatch) {
        var dropdowns = document.getElementsByClassName("subtab-menu-dropdown");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
            }
        }
    }
}

const ShiftTile = {

    assignCloseSidePanel() {
        const elements = document.getElementsByClassName('side-panel-close');
        if (elements.length > 0) {
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                const elementId = element.dataset.elementId;
                element.addEventListener('click', () => ShiftTile.closeSidePanel(elementId));
            }
        }
    },

    closeSidePanel(elementID) {
        var theContainer = document.getElementById(elementID)
        theContainer.style.width = "0px"
    },

    addLaneAssignmentListeners() {
        var lane_assignment_tabs = document.getElementsByClassName('shift-lane-assignment-tab')
        for (var i = 0; i < lane_assignment_tabs.length; i++) {
            ShiftTile.addLaneAssignmentListener(lane_assignment_tabs[i])
        }
    },

    addLaneAssignmentListener(lane_assignment_tab) {
        lane_assignment_tab.addEventListener('click', function (event) {
            closeAllPanels()
            // console.log("Tab Container ID: ", lane_assignment_tab.parentNode.id)
            var panelID = "lane-assignments-" + lane_assignment_tab.parentNode.parentNode.id
            // console.log("Panel ID: ", panelID)
            var side_panel = document.getElementById(panelID)
            side_panel.style.width = '350px'
        })
    },

    addScoreSheetsListeners() {
        var score_sheet_tabs = document.getElementsByClassName('shift-score-sheets-tab')
        for (var i = 0; i < score_sheet_tabs.length; i++) {
            ShiftTile.addScoreSheetListener(score_sheet_tabs[i])
        }
    },

    addScoreSheetListener(score_sheet_tab) {
        score_sheet_tab.addEventListener('click', function (event) {
            ShiftTile.closeAllPanels()
            // console.log("Tab Container ID: ", score_sheet_tab.parentNode.id)
            var panelID = "score-sheets-" + score_sheet_tab.parentNode.parentNode.id
            // console.log("Panel ID: ", panelID)
            var side_panel = document.getElementById(panelID)
            side_panel.style.width = '350px'
        })
    },

    addScoresListeners() {
        var scores_tabs = document.getElementsByClassName('shift-scores-tab')
        for (var i = 0; i < scores_tabs.length; i++) {
            ShiftTile.addScoreListener(scores_tabs[i])
        }
    },

    addScoreListener(scores_tab) {
        scores_tab.addEventListener('click', function (event) {
            ShiftTile.closeAllPanels()
            // console.log("Tab Container ID: ", scores_tab.parentNode.id)
            var panelID = "scores-" + scores_tab.parentNode.parentNode.id
            // console.log("Panel ID: ", panelID)
            var side_panel = document.getElementById(panelID)
            side_panel.style.width = '350px'
        })
    },

    addStatusesListeners() {
        var status_icons = document.getElementsByClassName('shift-tile-icon')
        for (var i = 0; i < status_icons.length; i++) {
            ShiftTile.addStatusListener(status_icons[i])
        }
    },

    addStatusListener(statusIcon) {
        // console.log("HIT addStatusListener")
        statusIcon.addEventListener('click', function (event) {
            ShiftTile.closeAllPanels()
            // console.log("Tab Container ID: ", statusIcon.parentNode.parentNode.id)
            var panelID = "shift-status-" + statusIcon.parentNode.parentNode.id
            // console.log("Panel ID: ", panelID)
            var side_panel = document.getElementById(panelID)
            side_panel.style.width = '350px'
        })
    },

    closeAllPanels() {
        var panels = document.getElementsByClassName('side-panel-container')
        for (var i = 0; i < panels.length; i++) {
            ShiftTile.closeSidePanel(panels[i].id)
        }
    }
}

export default ShiftTile;


