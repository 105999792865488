import { render, staticRenderFns } from "./organizer_balances_table.vue?vue&type=template&id=781e0648&scoped=true&"
import script from "./organizer_balances_table.vue?vue&type=script&lang=js&"
export * from "./organizer_balances_table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "781e0648",
  null
  
)

export default component.exports