<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="averages"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                label="Search"
                class="filter-field"
                clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                v-model="gameCountFilterValue"
                label="Minimum games"
                class="filter-field"
                clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
                :items="divisionsList"
                v-model="divisionsFilterValue"
                label="Division"
                clearable
                class="filter-field"
            ></v-select>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "season_averages_table",
  props: {
    averages: {
      type: Array
    },
    divisions: {
      type: Array
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'bowler_fullname',
          align: 'start',
        },
        {
          text: 'Count',
          value: 'game_count',
          align: 'end',
          filter: this.gameCountFilter
        },
        {
          text: 'Division',
          value: 'division_name',
          align: 'center',
          filter: this.divisionsFilter
        },
        {
          text: 'Average',
          value: 'average',
          align: 'end'
        },
      ],
      search: '',
      divisionsList: this.divisions,
      divisionsFilterValue: '',
      gameCountFilterValue: ''
    }
  },
  methods: {
    divisionsFilter(value) {

      // If this filter has no value we just skip the entire filter.
      if (!this.divisionsFilterValue) {
        return true;
      }

      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.divisionsFilterValue;
    },
    gameCountFilter(value) {
      if(!this.gameCountFilterValue){
        return true
      } else {
        return value >= parseInt(this.gameCountFilterValue)
      }
    }
  }
}
</script>

<style scoped>

</style>