<template>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{organizer_name}}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div >
            <a class="bulk-action-link-button white-link add-record" :href="getAddAwardTitleLink()">Add new title</a>
          </div>
          <v-data-table
              :headers="headers"
              :items="award_titles"
              :items-per-page="10"
              :search="search"
              class="elevation-1"
          >
            <template v-slot:top>
              <v-text-field
                  v-model="search"
                  label="Search"
                  class="mx-4"
              ></v-text-field>
            </template>
            <template v-slot:item.edit="props">
              <div v-if="props.item.custom">
                <a class="bulk-action-link-button white-link" :href="getEditAwardTitleLink(props.item)">Edit</a>
              </div>
            </template>
            <template v-slot:item.custom="{ item }">
              <v-chip v-if="!item.custom"
                      :color="'orange'"
                      dark
              >
                Global
              </v-chip>
            </template>
          </v-data-table>

        </v-expansion-panel-content>
      </v-expansion-panel>
</template>

<script>
export default {
  name: "award_titles_table",
  props: {
    award_titles: {
      type: Array
    },
    organizer_name: {
      type: String
    },
    organizer_id: {
      type: Number
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Title',
          value: 'award_title'
        },
        {
          text: 'Global',
          value: 'custom',
          align: 'center'
        },
        {
          text: 'Display Order',
          value: 'display_order',
          align: 'end'
        },
        {
          text: '',
          value: 'edit',
          align: 'center',
          sortable: false
        },
      ],
      search: '',
      passed_organizer_id: this.organizer_id
    }
  },
  methods: {
    getEditAwardTitleLink(value) {
      return '/award_titles/' + value.id + '/edit'
    },
    getAddAwardTitleLink() {
      if(this.passed_organizer_id){
      return '/award_titles/new?organizer_id=' + this.passed_organizer_id

      } else {
        return '/award_titles/new?global=true'
      }
    }
  },
}
</script>

<style scoped>

</style>