const ExpandFirstTab = {

    expandFirstAccordion() {
        var theUrl = window.location.href;
        if (theUrl.includes("/organizer_users")) {
            var firstAccordion = document.getElementsByClassName('accordion');
            if (firstAccordion.length == 1) {
                firstAccordion = firstAccordion[0];
                firstAccordion.classList.toggle("active")
                var panel = firstAccordion.nextElementSibling;
                panel.style.display = 'block';
            }
        }
    }
}

export default ExpandFirstTab
