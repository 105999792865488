<template>
  <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      :key="'side-nav-component'"
      v-on:transitionend="closeDrawer"
  >
    <v-list
        nav
        :dense="true"
    >
      <template v-for="link in navLinks">
        <template v-if="link.text !== 'divider'">
          <v-list-item v-if="!link.subLinks" :to="link.route">
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item>
          <v-list-group v-if="link.subLinks && link.subLinks.length > 0"
                        v-model="tournamentGroup"

          >
            <v-list-item slot="activator">
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-for="subLink in link.subLinks" :to="subLink.route">
              <template v-if="subLink.icon">
                <v-list-item-icon>
                  <v-icon>{{ subLink.icon }}</v-icon>
                </v-list-item-icon>
              </template>
              <v-list-item-title>{{ subLink.text }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
        <template v-else>
          <v-divider></v-divider>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "side_nav_main",
  props: {
    drawer_is_open: {
      type: Boolean
    },
    links: {
      type: Array
    }
  },
  data() {
    return {
      drawer: this.drawer_is_open,
      group: null,
      tournamentGroup: false,
      baseUrl: 'http://localhost:3030',
      navLinks: this.links
    }
  },
  methods: {
    closeDrawer(event) {
      if (this.drawer_is_open === true && this.drawer === false) {
        this.$root.$data.store.closeSideNav()
      }
    }
  },
  watch: {
    drawer_is_open: function (newVal, oldVal) {
      this.drawer = this.drawer_is_open
    }
  }
}
</script>

<style scoped>

</style>