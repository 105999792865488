<template>
  <div>
    <v-radio-group v-model="radioValue">
      <v-radio :value="false" :label="'Opening blocks'"></v-radio>
      <v-radio :value="true" :label="'Blocks following the selected cutline'"></v-radio>
    </v-radio-group>
    <div v-if="radioValue">
      <v-select
          :key="selectedSquad"
          :placeholder="'Blocks immediately following'"
          :items="items"
          :clearable="true"
          :outlined="true"
          :name="'squad_tournament[block_tournament_id]'"
          :value="selectedSquad"
      >
      </v-select>
    </div>
  </div>
</template>
<script>
export default {
  name: "squad_tournament_form",
  props: {
    cutline_blocks: {
      type: Array
    }
  },
  data() {
    return {
      radioValue: false,
      items: this.cutline_blocks,
      selectedSquad: null
    }
  }
}
</script>

<style scoped>

</style>