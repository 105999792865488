const EventListeners = {

    assignByClassName(className, eventType, passedFunction) {
        const elements = document.getElementsByClassName(className);
        for (let i = 0; i < elements.length; i++) {
            elements[i].addEventListener(eventType, passedFunction);
        }
    },
}

export default EventListeners;