<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="standings"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
            clearable
        ></v-text-field>
      </template>
      <template v-slot:item.qualified="{ item }">
        <v-chip v-if="item.qualified === 'Qualified'"
                :color="'green'"
                dark
        >
          {{ item.qualified }}
        </v-chip>
      </template>
      <template v-slot:item.college_student="{ item }">
        <v-chip v-if="item.college_student === true"
                :color="'orange'"
                dark
        >
          Yes
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "invitational_standings_table",
  props: {
    standings: {
      type: Array
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'bowler_fullname',
        },
        {
          text: 'Count',
          value: 'attendance_count',
          align: 'center'
        },
        {
          text: 'College',
          value: 'college_student',
          align: 'center'
        },
        {
          text: 'Qualified',
          value: 'qualified',
          align: 'center'
        },
      ],
      search: '',
    }
  },
}
</script>

<style scoped>

</style>