// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import BowlerTournamentRegistration from "./custom/bowler_tournament_registrations";
import BlockTournaments from "./custom/block_tournaments";
import BowlerTournaments from "./custom/bowler_tournaments";
import CompetitorScoringRules from "./custom/competitor_scoring_rules";
import ScoringRules from "./custom/scoring_rules";
import Menu from "./custom/menu";
import ShiftSquadLaneAssignments from "./custom/shift_squad_lane_assignments";
import ShiftTile from "./custom/shift_tile";
import SquadTournament from "./custom/squad_tournament";
import Tournament from "./custom/tournament";
import ExpandFirstTab from "./custom/expand_first_accordion";
import Accordion from "./custom/accordion";
import Tab from "./custom/tab_row";
import SubTab from "./custom/subtab_row";
import CompetitorScores from "./custom/competitor_scores";
import Block_type from "../components/block_type";
import Squad_tournament_form from "../components/squad_tournament_form";
import Buy_button from "./custom/buy_button"

import TurbolinksAdapter from 'vue-turbolinks'

import Vue from 'vue/dist/vue.esm'
import App from '../components/app.vue'
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'
import VueRouter from "vue-router";

import Shift_tournament_datetime from '../components/shift_tournament_datetime'
import Reg_import_progress from "../components/reg_import_progress";
import Registration_import_result from "../components/registration_import_result";
import Shift_score_sheet_pair_status from "../components/shift_score_sheet_pair_status";
import Tournament_advanced_options from "../components/tournament_advanced_options";
import Checkbox_hidden_field from "../components/checkbox_hidden_field";
import Lane_assignment_options from "../components/lane_assignment_options";
import Bowler_search from "../components/bowler_search";
import Scoring_rule_create_form from "../components/scoring_rule_create_form";
import Cutline_form from "../components/cutline_form";
import Shopify_buy_button from "../components/shopify_buy_button";
import Scoring_rule_result_block_averages from "../components/scoring_rule_results_block_averages";
import Lane_assignment_alphabet from "../components/lane_assignment_alphabet";
import Top_nav_main from "../components/top_nav_main";
import Side_nav_main from "../components/side_nav_main";
import Side_nav_outer from "../components/side_nav_outer";
import Custom_line_chart from "../components/custom_line_chart";
import Custom_gauge_chart from "../components/custom_gauge_chart";
import Custom_mixed_chart from "../components/custom_mixed_chart";
import Bowler_profile_stats from "../components/bowler_profile_stats";
import Bowler_profile_absolute_value from "../components/bowler_profile_absolute_value";
import Tournaments_table from "../components/tournaments_table";
import Bowler_profile_results_table from "../components/bowler_profile_results_table";
import Bowler_profile_averages_table from "../components/bowler_profile_averages_table";
import Bowler_profile_awards_table from "../components/bowler_profile_awards_table";
import Invitational_standings_table from "../components/invitational_standings_table";
import Bowler_championships_table from "../components/bowler_championships_table";
import Season_averages_table from "../components/season_averages_table";
import Results_organizers_table from "../components/results_organizers_table";
import Results_years_table from "../components/results_years_table";
import Organizer_season_results_table from "../components/organizer_season_results_table";
import Award_titles_table from "../components/award_titles_table";
import Bowler_profile_qualifications_table from "../components/bowler_profile_qualifications_table";
import InfoIntroCarousel from "../components/info_intro_carousel";
import Tournament_results_awards_table from "../components/tournament_results_awards_table";
import Tournament_results_qualifiers_table from "../components/tournament_results_qualifiers_table";
import Teams_table from "../components/teams_table";
import Bowler_tournaments_table from "../components/bowler_tournaments_table";
import Organizer_balances_table from "../components/organizer_balances_table";
import Paypal_poc from "../components/paypal_poc";
import Purchase_credit from "../components/purchase_credit";
import Organizer_transactions_table from "../components/organizer_transactions_table";
import Shift_tournament_table from "../components/shift_tournament_table";
import Competition_qualifiers_table from "../components/competition_qualifiers_table";
import Competitor_scoring_rules_table from "../components/competitor_scoring_rules_table";
import Competitor_squad_tournaments_table from "../components/competitor_squad_tournaments_table";
import Awards_table from "../components/awards_table";
import Lane_pairs_table from "../components/lane_pairs_table";
import Shift_divisions_table from "../components/shift_divisions_table";
import Competitor_type_select from "../components/competitor_type_select";
import Oil_patterns_table from "../components/oil_patterns_table";
import Imported_buy_button from "../components/imported_buy_button";
import Bowler_profile_search_table from "../components/bowler_profile_search_table";
import Championship_scale_table from "../components/championship_scale_table";
import Score_export_selection from "../components/score_export_selection";
import Tournament_card from "../components/tournament_card";

import("jquery")
import("./custom/accordion")
import("./custom/block_tournaments")
import("./custom/bowler_tournaments")
import("./custom/bowler_tournament_registrations")
import("./custom/competitor_scoring_rules")
import("./custom/event_listeners")
import("./custom/menu")
import("./custom/scoring_rule_results")
import("./custom/scoring_rules")
import("./custom/shift_squad_lane_assignments")
import("./custom/shift_tile")
import("./custom/squad_tournament")
import("./custom/subtab_row")
import("./custom/tab_row")
import("./custom/tournament")

Rails.start()
Turbolinks.start()
ActiveStorage.start()
Vue.use(TurbolinksAdapter)
Vue.use(Vuetify)
Vue.use(VueRouter)

Vue.component('app', App)
Vue.component('shift_tournament_datetime', Shift_tournament_datetime)
Vue.component('reg_import_progress', Reg_import_progress)
Vue.component('shift_score_sheet_pair_status', Shift_score_sheet_pair_status)
Vue.component('registration_import_result', Registration_import_result)
Vue.component('tournament_advanced_options', Tournament_advanced_options)
Vue.component('checkbox_hidden_field', Checkbox_hidden_field)
Vue.component('block_type', Block_type)
Vue.component('lane_assignment_options', Lane_assignment_options)
Vue.component('bowler_search', Bowler_search)
Vue.component('squad_tournament_form', Squad_tournament_form)
Vue.component('scoring_rule_create_form', Scoring_rule_create_form)
Vue.component('cutline_form', Cutline_form)
Vue.component('shopify_buy_button', Shopify_buy_button)
Vue.component('scoring_rule_result_block_averages', Scoring_rule_result_block_averages)
Vue.component('lane_assignment_alphabet', Lane_assignment_alphabet)
Vue.component('top_nav_main', Top_nav_main)
Vue.component('side_nav_main', Side_nav_main)
Vue.component('side_nav_outer', Side_nav_outer)
Vue.component('custom_line_chart', Custom_line_chart)
Vue.component('custom_gauge_chart', Custom_gauge_chart)
Vue.component('custom_mixed_chart', Custom_mixed_chart)
Vue.component('bowler_profile_stats', Bowler_profile_stats)
Vue.component('bowler_profile_absolute_deviation', Bowler_profile_absolute_value)
Vue.component('tournaments_table', Tournaments_table)
Vue.component('bowler_profile_results_table', Bowler_profile_results_table)
Vue.component('bowler_profile_averages_table', Bowler_profile_averages_table)
Vue.component('bowler_profile_awards_table', Bowler_profile_awards_table)
Vue.component('invitational_standings_table', Invitational_standings_table)
Vue.component('bowler_championships_table', Bowler_championships_table)
Vue.component('season_averages_table', Season_averages_table)
Vue.component('results_organizers_table', Results_organizers_table)
Vue.component('results_years_table', Results_years_table)
Vue.component('organizer_season_results_table', Organizer_season_results_table)
Vue.component('award_titles_table', Award_titles_table)
Vue.component('bowler_profile_qualifications_table', Bowler_profile_qualifications_table)
Vue.component('infoIntroCarousel', InfoIntroCarousel)
Vue.component('tournament_results_awards_table', Tournament_results_awards_table)
Vue.component('tournament_results_qualifiers_table', Tournament_results_qualifiers_table)
Vue.component('teams_table', Teams_table)
Vue.component('bowler_tournaments_table', Bowler_tournaments_table)
Vue.component('organizer_balances_table', Organizer_balances_table)
Vue.component('paypal_poc',Paypal_poc)
Vue.component('purchase_credit', Purchase_credit)
Vue.component('organizer_transactions_table', Organizer_transactions_table)
Vue.component('shift_tournament_table', Shift_tournament_table)
Vue.component('competition_qualifiers_table', Competition_qualifiers_table)
Vue.component('competitor_scoring_rules_table', Competitor_scoring_rules_table)
Vue.component('competitor_squad_tournaments_table', Competitor_squad_tournaments_table)
Vue.component('awards_table', Awards_table)
Vue.component('lane_pairs_table', Lane_pairs_table)
Vue.component('shift_divisions_table', Shift_divisions_table)
Vue.component('competitor_type_select', Competitor_type_select)
Vue.component('oil_patterns_table', Oil_patterns_table)
Vue.component('imported_buy_button', Imported_buy_button)
Vue.component('bowler_profile_search_table', Bowler_profile_search_table)
Vue.component('championship_scale_table', Championship_scale_table)
Vue.component('score_export_selection', Score_export_selection)
Vue.component('tournament_card', Tournament_card)

const base_url = "" + process.env.NAVIGATION_BASE_URL

const routes = [
    {
        path: '/home',
        beforeEnter(to, from, next) {
            window.location.href = base_url
        }
    },
    {
        path: '/users/edit',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/users/edit'
        }
    },
    {
        path: '/users/sign_in',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/users/sign_in'
        }
    },
    {
        path: '/users/sign_out',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/users/sign_out'
        }
    },
    {
        path: '/users/sign_up',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/users/sign_up'
        }
    },
    {
        path: '/users/log_out',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/users/log_out'
        }
    },
    {
        path: '/all_results',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/all_results'
        }
    },
    {
        path: '/upcoming_tournaments',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/upcoming_tournaments'
        }
    },
    {
        path: '/bowler_profiles',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/bowler_profiles'
        }
    },
    {
        path: '/administrations',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/administrations'
        }
    },
    {
        path: '/organizer_balances',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/organizer_balances'
        }
    },
    {
        path: '/organizer_configurations',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/organizer_configurations'
        }
    },
    {
        path: '/scores/search',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/scores/search'
        }
    },
    {
        path: '/tournaments',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/tournaments'
        }
    },
    {
        path: '/tournaments/:id',
        beforeEnter(to, from, next) {
            window.location.href = base_url + '/tournaments'
        }
    }
]

const router = new VueRouter({
    routes // short for `routes: routes`
})

const vuetify = new Vuetify();



document.addEventListener('turbolinks:load', () => {
    const app = new Vue({
        data: {
            store: {
                sideNavOpen: false,
                foo: true,
                displayPayPalButtons: false,
                openSideNav() {
                    this.sideNavOpen = true
                },
                closeSideNav() {
                    this.sideNavOpen = false
                },
                openPayPal() {
                    this.displayPayPalOptions = true
                },
                closePayPal() {
                    this.displayPayPalOptions = false
                }
            }
        },
        el: '[data-behavior="vue"]',
        vuetify,
        router,
        components: {App}
    })
})

document.addEventListener('turbolinks:load', () => {

    // console.log('HIT Accordion Load')
    Accordion.assignAccordions()

    // console.log('HIT BlockTournament')
    BlockTournaments.assignAdminButtonListeners();
    BlockTournaments.assignCompetitorTypeListener();
    BlockTournaments.assignSidePanelCloseListeners();
    BlockTournaments.assignBlockHandicapListener();

    // console.log('HIT BowlerTournamentRegistration')
    BowlerTournamentRegistration.assignBowlerNotFoundListener()

    // console.log('HIT BowlerTournaments')
    BowlerTournaments.addEnableCompetitionButtonListener();
    BowlerTournaments.addCutlineGroupListener();

    CompetitorScores.assigEnableCompetitorScoreSaveListener()

    // console.log('HIT CompetitorScoringRules')
    CompetitorScoringRules.assignOpenCompSquads();

    // console.log('HIT ExpandFirstTab')
    ExpandFirstTab.expandFirstAccordion();

    // console.log('HIT Menu')
    Menu.addListeners()

    // console.log('HIT ScoringRules')
    ScoringRules.assignJGQualifierSeasonListener();
    ScoringRules.assignScoringRuleTypeListener();
    ScoringRules.assignToggleScoringDetailsListener();

    // console.log('HIT ShiftSquadLaneAssignments')
    ShiftSquadLaneAssignments.assignAssignmentSchemeHelpListener();

    // console.log('HIT ShiftTile')
    ShiftTile.addLaneAssignmentListeners()
    ShiftTile.addScoreSheetsListeners()
    ShiftTile.addScoresListeners()
    ShiftTile.addStatusesListeners()
    ShiftTile.assignCloseSidePanel();

    // console.log('HIT Squad Tournament')
    SquadTournament.assignSquaCutlineBlocksListener()

    // console.log('HIT SubTab')
    SubTab.addListeners()

    // console.log('HIT Tab')
    Tab.assignTabListeners()

    // console.log('HIT Tournament')
    Tournament.assignTournamentAdvancedOptionsListener();
    Buy_button.addBuyButton()
})

//THis is to check if the contact request form is filled by a robot
$('form').submit(function () {
    if ($('input#website').val().length !== 0) {
        return false;
    }
});