<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="awards"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
            clearable
        ></v-text-field>
      </template>
      <template v-slot:item.tournament_title="props">
        <a :href="getTournamentResultsLink(props.item)">{{props.item.tournament_title}}</a>
      </template>
      <template v-slot:item.amount="props">
        {{getCurrentAmount(props.item.amount)}}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "bowler_profile_awards_table",
  props: {
    awards: {
      type: Array
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Title',
          value: 'award_title'
        },
        {
          text: 'Division',
          value: 'division_name',
          align: 'center'
        },
        {
          text: 'Tournament',
          value: 'tournament_title',
          align: 'start'
        },
        {
          text: 'Season',
          value: 'season_title',
          align: 'start',
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'end'
        }
      ],
      search: '',
      foo: ''
    }
  },
  methods: {
    getTournamentResultsLink(value) {
      return '/tournament_results/' + value.tournament_id
    },
    getCurrentAmount(value) {
      return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(value)
    }
  },
}
</script>

<style scoped>

</style>