<template>
  <div class="import-result">
    <v-card elevation="4" outlined>
      <v-card-title>
        Import at: {{ createdAt }}
      </v-card-title>
      <v-card-text>

      <v-progress-circular v-if="currentProgress !== '100%'"
                           :value="currentProgress"
                           color="blue-grey"
      ></v-progress-circular>
<!--      <div>-->
        <span v-else class="complete-text">Complete: </span>
        Total: {{ total }}
        <span :key="`${result_id}-pSuccesses`">Imported: {{ pSuccesses }}</span>
        <span :key="`${result_id}-pFailures`">Failures: {{ pFailures }}</span>
<!--      </div>-->
      </v-card-text>

      <div>
        <v-expansion-panels>
          <v-expansion-panel
              :key="historyId"
          >
            <v-expansion-panel-header>
              Errors
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ errorJson }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import RegistrationImport from "../packs/custom/registration_import";

export default {
  name: "reg_import_progress",
  props: {
    historyId: {
      type: String
    },
    createdAt: {
      type: String
    },
    total: {
      type: String
    },
    processed: {
      type: String
    },
    successes: {
      type: String
    },
    failures: {
      type: String
    },
    errorMessages: {
      type: String
    }
  },
  data() {
    return {
      info: null,
      result_id: this.historyId,
      passedProcessed: this.processed,
      passedTotal: this.total,
      pSuccesses: this.successes,
      pFailures: this.failures,
      errorJson: this.errorMessages,
      refreshInterval: null,
      showRunningVar: false,
      URL: process.env.VUE_APP_API_HOST
    }
  },
  mounted() {
    if (this.passedProcessed !== this.passedTotal && !this.refreshInterval) {
      this.reloadData()
    }
  },
  beforeUnmount() {
    console.log("HIT beforeUnmount")
    clearInterval(this.refreshInterval)
  },
  methods: {
    reloadData() {
      this.showRunningVar = true
      let completeURL = this.URL + "/registration_import_results/" + this.result_id
      console.log("API Path: ", completeURL)
      this.refreshInterval = setInterval(() => {
        axios
            .get(completeURL)
            .then(response => {
              this.info = response
              this.passedProcessed = response.data.processed
              this.pSuccesses = response.data.successes
              this.pFailures = response.data.failures
              this.errorJson = response.data.error_json
              if (this.info.data.processed === null || this.info.data.processed === this.info.data.total) {
                this.showRunningVar = false
                clearInterval(this.refreshInterval)
              }
            })
      }, 5000)
    },
  },
  computed: {
    currentProgress: function () {
      return parseFloat((this.passedProcessed / this.total) * 100).toFixed(0) + "%"
    }
  }
}
</script>

<style scoped>
.complete-text {
  font-weight: bold;
}
.v-progress-circular {
  margin: 1rem;
}
</style>