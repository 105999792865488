<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="averages"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
            clearable
        ></v-text-field>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "bowler_profile_averages_table",
  props: {
    averages: {
      type: Array
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Season Title',
          align: 'start',
          value: 'season_title',
        },
        {
          text: 'Average',
          value: 'average',
          align: 'end'
        },
        {
          text: 'Abs.Dev.',
          value: 'absolute_deviation',
          align: 'end'
        },
      ],
      search: '',
    }
  },
}
</script>

<style scoped>

</style>