
const BowlerTournaments = {

    addCutlineGroupListener() {
        let buttons = document.getElementsByClassName('csr-clear-selection-button');
        if (buttons) {
            for (let i = 0; i < buttons.length; i++) {
                const cutlineGroupId = buttons[i].dataset.cutlinegroupId
                buttons[i].addEventListener('click', () => BowlerTournaments.clearCutlineGroupSelection(cutlineGroupId));
            }
        }
    },

    addEnableCompetitionButtonListener() {
        let buttons = document.getElementsByClassName('cutline-radion-button');
        if (buttons) {
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener('change', () => BowlerTournaments.enableCompetitionSubmit());
            }
        }
    },

    enableCompetitionSubmit() {
        let x = document.getElementById("competitions_save")
        x.disabled = false;
        x.className = x.className.replace(" submit_inactive", " submit_active");
    },

    clearCutlineGroupSelection(target_class) {
        BowlerTournaments.enableCompetitionSubmit();
        let x = document.getElementsByClassName(target_class);
        for (let i = 0; i < x.length; i++) {
            x[i].checked = false;
        }
    }
}

export default BowlerTournaments;